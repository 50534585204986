.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.burntToken {
  text-decoration: line-through;
  background: url("src/themes/default/assets/img/burnt-bg.svg") #fff5f5;
}

hr {height: 1px; background-color: #EEE; border: none;}