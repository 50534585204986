.fxt-logo .login-logo {
    margin: 0px auto;
}
.nft-login-template {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  position: relative;
  z-index: 1;
}
.nft-login-template::before {
    content: "";
    background-image: url(../images/login-background.jpg);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}
.nft-login-template::after {
    content: "";
    background-color: #222;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.6;
}
.nft-login-template .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.nft-login-template .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.nft-login-template .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.nft-login-template .fxt-content {
    padding: 65px 90px 45px;
    width: 100%;
    background-color: #fff;
    position: relative;
    z-index: 1;
}
.nft-login-template .fxt-content .fxt-home-link {
    position: absolute;
    top: 15px;
    left: 15px;
}
.nft-login-template .fxt-content .fxt-home-link > a {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 800;
    color: #222;
}
@media only screen and (max-width: 767px) {
  .nft-login-template .fxt-content {
    padding: 65px 30px 45px;
  }
}
.nft-login-template .fxt-header {
    text-align: center;
    margin-bottom: 28px;
}
.nft-login-template .fxt-header .fxt-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 7px;
  max-width: 150px;
}
.nft-login-template .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.nft-login-template .fxt-header p {
  color: #999898;
}
.nft-login-template .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.nft-login-template .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.nft-login-template .fxt-form form .input-label {
  color: #979696;
}
.nft-login-template .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.nft-login-template .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.nft-login-template .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.nft-login-template .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.nft-login-template .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-btn-fill {
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #111;
  padding: 10px 36px;
  margin-bottom: 0px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.nft-login-template .fxt-btn-fill:hover {
  background-color: #333;
  border-color: #333;
}
.nft-login-template .fxt-btn-fill:focus {
  outline: none;
}
.nft-login-template .switcher-text {
    color: #222222;
    font-weight: 800;
    font-size: 15px;
    margin-bottom: 0px;
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.nft-login-template .switcher-text:hover {
  color: #000000;
}
.nft-login-template .switcher-text2 {
    color: #222;
    font-weight: 800;
    font-size: 15px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.nft-login-template .switcher-text2.inline-text {
  margin-left: 3px;
}
.nft-login-template .switcher-text2:hover {
  color: #000000;
}
.nft-login-template .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.nft-login-template .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.nft-login-template .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.nft-login-template .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.nft-login-template ul.fxt-socials {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.nft-login-template ul.fxt-socials li {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .nft-login-template ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .nft-login-template ul.fxt-socials li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.nft-login-template ul.fxt-socials li a {
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nft-login-template ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.nft-login-template ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.nft-login-template ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.nft-login-template ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.nft-login-template ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.nft-login-template .checkbox {
  padding-left: 5px;
  margin-right: 30px;
  margin-bottom: 5px;
}
.nft-login-template .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.nft-login-template .checkbox label > a {
    font-weight: 600;
    color: #222;
}
.nft-login-template .checkbox label:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nft-login-template .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.nft-login-template .checkbox input[type="checkbox"] {
  display: none;
}
.nft-login-template .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 3px;
}
.nft-login-template .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #111;
}
.nft-login-template .fxt-footer {
  text-align: center;
}
.nft-login-template .fxt-footer p {
  color: #999898;
}
.nft-login-template p.Mui-error {text-align: left; font-size: 14px; color: #d32f2f !important;}