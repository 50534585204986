@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*
====================================
[ CSS TABLE CONTENT ]
------------------------------------
    1.0  - custom css
    2.0  - header css
    3.0  - slider css
    4.0  - singel about css
    5.0  - top ranked css
    6.0  - item group css
    8.0  - hot bids css
    9.0  - earn css
    10.0 - mintable css
    11.0 - top selling css
    12.0 - mintable guides css
    12.0 - bradcrumb css
    14.0 - browse product css
    15.0 - nft dao css
    16.0 - buy votes css
    17.0 - earn css
    18.0 - proposals css
    19.0 - view store css
    20.0 - pro stores css
    21.0 - pricing css
    22.0 - user screen css
    23.0 - browse detail css
    24.0 - starting bid css
    25.0 - faq css
    26.0 - discover css
    27.0 - nft filter css
    28.0 - search css
    29.0 - list select css
    30.0 - min item css
    31.0 - traditional gasless css
    32.0 - fix price css
    33.0 - wallet detected css
    34.0 - checkout css
    35.0 - order sucsess css
    36.0 - footer css
    37.0 - back to top css
    38.0 - keyframes css
-------------------------------------
[ END CSS TABLE CONTENT ]
=====================================
*/
/* =============================================
                Theme Reset Style
============================================= */
body {
	font-family: "Mulish", sans-serif !important;
	overflow-x: hidden !important;
	letter-spacing: 0.5px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: "Mulish", sans-serif !important;
	letter-spacing: 0.5px !important;
}
ul,
li,
ol,
li {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
a {
	text-decoration: none !important;
	color: #f38a25;
}
img {
	max-width: 100%;
}
button {
	cursor: pointer;
}
::-webkit-scrollbar-track {
	border-radius: 6px;
	background-color: #fff;
}
::-webkit-scrollbar {
	width: 6px;
	background-color: #f38a25;
}
::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: #f38a25;
}
/* custom css */
.theme-btn {
	font-weight: 700;
	font-size: 15px;
	border: none;
	padding: 13px 31px;
	border-radius: 5px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
	transition: 0.9s;
	display: inline-block;
	background-color: #f38a25;
	color: #fff;
	font-family: "Mulish", sans-serif;
	position: relative;
	letter-spacing: 1px;
	cursor: pointer;
}
.theme-btn > i {
	margin-left: 3px;
}
.theme-btn:hover {
	color: #ffffff;
}
.theme-btn.transparent-btn {
	border: 2px solid #f38a25;
	background-color: transparent;
	color: #f38a25;
}
.theme-btn.transparent-btn::before,
.theme-btn.transparent-btn::after {
	border-radius: 0px;
}
.theme-btn.transparent-btn:hover {
	color: #ffffff;
}
.theme-btn::after,
.theme-btn::before {
	content: "";
	position: absolute;
	top: 0;
	height: 100%;
	width: 0;
	transition: 0.4s;
	background: #00247e;
	z-index: -1;
}
.theme-btn::after {
	border-radius: 5px 0px 0px 5px;
}
.theme-btn::before {
	border-radius: 0px 5px 5px 0px;
}
.theme-btn::after {
	left: 0;
}
.theme-btn::before {
	right: 0;
}
.theme-btn:hover::after,
.theme-btn:hover::before {
	background: #00247e;
}
.theme-btn:hover::after,
.theme-btn:hover::before {
	width: 50%;
}
.theme-title {
	font-size: 18px;
	font-weight: 700;
	margin: 0px;
	font-family: "Mulish", sans-serif;
	line-height: 1.4;
	color: #222222;
	text-transform: capitalize;
}
.theme-title > a {
	color: #222;
}
.theme-title > a:hover {
	color: #f38a25;
}
.theme-description {
	font-size: 16px;
	color: #777777;
	line-height: 1.6;
	margin: 0px;
	font-weight: 400;
}
.theme-description > a {
	color: #f38a25;
	font-weight: 600;
}
.owl-loaded {
	overflow: hidden;
	position: relative;
}
.owl-item {
	float: left;
}
.owl-nav.disabled {
	display: none;
}
.owl-nav {
	text-align: center;
}
.owl-nav > button {
	height: 45px;
	width: 45px;
	background-color: #004e9a;
	color: #ffffff;
	border: 0px;
	border-radius: 5px;
	font-size: 20px;
	text-align: center;
	line-height: 44px;
	padding: 0px;
	outline: 0 !important;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	transition: all 0.5s;
	opacity: 0;
	visibility: hidden;
}
.owl-loaded:hover .owl-nav > button {
	opacity: 1;
	visibility: visible;
}
.owl-nav > button.owl-prev {
	left: 28px;
}
.owl-nav > button.owl-next {
	right: 28px;
}
.owl-dots {
	text-align: center;
	margin-top: 12px;
	display: inline-block;
	width: 100%;
}
.owl-dots .owl-dot {
	padding: 0px;
	border: 0px;
	height: 8px;
	width: 16px;
	background-color: #eee;
	border-radius: 6px;
	margin-right: 8px;
	outline: 0;
}
.owl-dots .owl-dot.active {
	background-color: #f38a25;
	width: 25px;
}
.owl-dots.disabled {
	display: none;
}
.page-paddings {
	padding: 150px 0px;
}
.page-background {
	background-color: #eff2f7;
	padding: 100px 0px;
}
.section-title h6 {
	margin-bottom: 15px;
	color: #f38a25;
	letter-spacing: 5px;
	font-size: 18px;
	font-weight: 800;
}
.section-title h2 {
	font-size: 42px;
	text-transform: capitalize;
	margin-top: -14px;
	margin-bottom: 15px;
	font-weight: 800;
	color: #222;
	position: relative;
	letter-spacing: 0.5px;
}
.section-title h2[data-watermark]::before {
	content: attr(data-watermark);
	position: absolute;
	left: 0;
	-webkit-text-stroke: 1px #eee;
	color: transparent;
	font-size: 60px;
	line-height: 0.9;
	white-space: nowrap;
	z-index: -1;
	top: 48%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
.section-title .subtitle {
	font-size: 16px;
	font-weight: 400;
	max-width: 600px;
	margin: 0px auto;
	color: #777777;
	line-height: 1.6;
}
.em_bar {
	height: 2px;
	margin: 0 0px 25px;
	width: 90px;
}
.section-title .em_bar {
	margin: 0px auto 25px;
}
.collection-description {
	text-align: center;
	margin: 25px 0 !important;
}
.em_bar_bg {
	height: 5px;
	width: 90px;
	background: rgb(12 90 219 / 20%);
	margin: 20px 0px;
	position: relative;
	border-radius: 30px;
}
.em_bar_bg:before {
	content: "";
	position: absolute;
	left: 0;
	top: -2.7px;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: #f38a25;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: MOVE-BG;
	animation-name: MOVE-BG;
}
.fade.in {
	opacity: 1;
}
.theme-custom-dropdown .dropdown-select {
	margin: 0px;
	text-align: left;
	cursor: pointer;
	position: relative;
	background-color: #fff;
	border: 1px solid #eee;
	box-shadow: 0px 0px 8px #eee;
	padding: 10px 12px;
	outline: 0;
	border-radius: 4px 4px;
	font-size: 15px;
	color: #444;

	font-family: "Mulish", sans-serif;
	width: 150px;
}
.theme-custom-dropdown .dropdown-select::before {
	content: "\f078";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	right: 15px;
	font-size: 14px;
	color: #888888;
}
.dropdown-menu {
	width: 240px;
	padding: 0;
	margin: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.theme-custom-dropdown .dropdown-menu {
	padding: 0px;
	margin: 6px 0px 0px;
	border: 1px solid #eee;
	width: 100%;
	box-shadow: 0px 0px 15px #eee;
}
.theme-custom-dropdown .dropdown-menu li {
	border-bottom: 1px solid #eee;
	padding: 10px 15px;
	color: #222;
	cursor: pointer;
	font-size: 15px;
}
.theme-custom-dropdown .dropdown-menu li > a {
	font-size: 15px;
	display: block;
	text-decoration: none;
	color: #222;
}
.default-switch {
	position: relative;
}
.theme-swithcher {
	padding: 0px;
	border: 0px;
	display: inline-block;
}
.default-switch a {
	font-weight: 600;
	color: #666 !important;
	font-size: 14px;
	text-decoration: none !important;
}
.default-switch .switch {
	display: inline-block;
	width: 35px;
	height: 20px;
	position: relative;
	top: 0px;
	right: 0px;
	margin: 0px;
}
.theme-swithcher .switch {
	top: 1px;
	left: -42px;
	margin: 0px;
}
.default-switch .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
.default-switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
}
.default-switch .slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 5px;
	bottom: 4px;
	background-color: white;
	transition: 0.4s;
}
.default-switch input:checked + .slider:before {
	-webkit-transform: translateX(14px);
	transform: translateX(14px);
}
/* Rounded sliders */
.default-switch .slider.round {
	border-radius: 34px;
}
.default-switch input:checked + .slider {
	background-color: #f38a25;
}
.default-switch .slider.round:before {
	border-radius: 50%;
}
.pagination-box > ul {
	margin: 30px 0px 0px;
}
.pagination-box > ul > li.current {
	background-color: #f38a25;
	color: #fff;
	border-radius: 100%;
	width: 45px;
	height: 45px;
	line-height: 46px;
	text-align: center;
	margin: 0 6px;
	display: inline-block;
	text-align: center;
	color: #fff;
}
.pagination-box > ul > li {
	color: #000;
	font-size: 16px;
	margin: 0 12px;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 500;
	font-family: "Mulish", sans-serif;
}
.pagination-box > ul > li > a {
	color: #000;
	transition: all 0.5s;
}
.pagination-box > ul > li > a > i {
	margin-left: 4px;
	vertical-align: text-bottom;
}
.pagination-box > ul > li > a:hover {
	color: #f38a25;
}
.styled-checkbox {
	position: absolute;
	opacity: 0;
}
.styled-checkbox + label {
	padding: 0;
	margin: 0px;
	position: relative;
	display: block;
	color: #4c5866;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}
.styled-checkbox + label > span {
	display: inline-block;
	position: relative;
	top: 2px;
	letter-spacing: 1px;
}
.styled-checkbox + label > span > a {
	color: #f38a25;
	font-weight: 700;
}
.styled-checkbox + label:before {
	content: "";
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 20px;
	height: 20px;
	background: #f3f6f8;
}
.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}
.styled-checkbox:disabled + label:before {
	box-shadow: none;
	background: #ddd;
}
.styled-checkbox:checked + label:after {
	content: "";
	position: absolute;
	left: 5px;
	top: 11px;
	background: #f38a25;
	width: 2px;
	height: 2px;
	box-shadow: 2px 0 0 #f38a25, 4px 0 0 #f38a25, 4px -2px 0 #f38a25, 4px -4px 0 #f38a25, 4px -6px 0 #f38a25,
		4px -8px 0 #f38a25;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.styled-checkbox[type="radio"] + label:before {
	border-radius: 50%;
}
.validate-input {
	position: relative;
}
.nft-input-box {
	width: 100%;
	position: relative;
	border-bottom: 2px solid #f4f4f4;
	padding-bottom: 10px;
	margin-top: 20px;
}
.label-nft-input {
	font-size: 14px;
	color: #888;
	line-height: 1.5;
	padding-left: 5px;
	font-family: "Mulish", sans-serif;
	display: block;
	text-align: left;
}
.nft-input {
	height: 40px;
}
.nft-input-box textarea.nft-input {
	height: auto;
	resize: none;
}
.nft-input {
	display: block;
	width: 100%;
	background: 0 0;
	font-size: 16px;
	color: #333;
	line-height: 1.2;
	padding: 0 5px;
	border: 0px;
	outline: 0;
}
.focus-nft-input {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}
.focus-nft-input::before {
	content: "";
	display: block;
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 0;
	height: 2px;
	transition: all 0.4s;
	background: #f38a25;
}
.nft-input:focus + .focus-nft-input::before {
	width: 100%;
}
body .modal-backdrop {
	background-color: #222222;
}
body .modal-backdrop.show {
	opacity: 0.98;
}
.modal.fade .modal-dialog {
	margin: 0px auto;
	position: fixed;
	top: 50px;
	left: 0px;
	right: 0px;
	-webkit-transform: none !important;
	        transform: none !important;
}
.theme-model-popup-header h3 {
	font-size: 22px;
	font-weight: 500;
	font-family: "Mulish", sans-serif;
	text-transform: capitalize;
	color: #222;
}
.theme-model-popup-header .theme-description {
	font-size: 14px;
}
.modal-content .modal-body {
	padding: 45px 40px 45px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow-y: auto;
	max-height: calc(100vh - 100px);
}
.modal-content .modal-body .close {
	position: absolute;
	top: 15px;
	right: 15px;
	font-size: 20px;
	color: #f38a25;
	opacity: 1;
	outline: 0;
}
.blockchain-popup-box .nft-input-box .nft-input {
	font-size: 15px;
}
.modal-footer .theme-btn {
	padding: 12px 30px;
}
.theme-model-popup .modal-footer {
	border: 0px;
	margin-top: 25px;
	margin-bottom: -10px;
	padding: 0px;
}
.modal-body .modal-footer {
	border: 0px;
	padding: 0px;
	margin-top: 30px;
}
.theme-input-box label {
	display: block;
	font-size: 15px;

	font-family: "Mulish", sans-serif;
	color: #818992;
	margin-bottom: 8px;
	text-transform: capitalize;
	font-weight: 500;
}
.theme-input-box label > i,
.styled-checkbox + label > span > i {
	cursor: pointer;
	margin-left: 2px;
	color: #f38a25;
}
.theme-input-box .theme-input {
	padding: 15px 15px;
	width: 100%;
	outline: 0;
	font-size: 15px;
	color: #222;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	line-height: normal;
	height: auto;
}
.theme-file-upload {
	background-color: rgb(57 72 104 / 2%);
	padding: 30px 30px;
	border-radius: 6px 6px;
}
.theme-file-upload .file-upload-ico {
	margin-bottom: 15px;
}
.theme-file-upload .theme-title {
	margin-bottom: 2px;
}
/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@keyframes zoomin {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomout {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.4);
		        transform: scale(0.4);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
@keyframes zoomout {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		-webkit-transform: scale(0.4);
		        transform: scale(0.4);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}
/* custom css */
/* header css */
.header-main {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 99;
	border-bottom: 1px solid #f4f4f4;
	background-color: #fff;
}
.header-main.fixed-header {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	box-shadow: 0px 0px 15px #cccccc;
	background-color: #fff;
	z-index: 99;
	-webkit-animation: slide-down 0.7s;
	        animation: slide-down 0.7s;
}
.header-menu-box .header-navbar-menu .navbar {
	padding: 0px;
}
.header-main .header-logo {
	padding: 5px 0px;
}
.header-main .header-menu-box {
	float: right;
	display: flex;
	align-items: center;
}
.header-main .col-md-9 {display: flex; align-items: center; justify-content: flex-end;}
.header-menu-box .header-navbar-menu {
	float: left;
}
.header-menu-box .header-right-btn {
	margin-left: 16px;
}
.header-menu-box .header-navbar-menu .navbar .nav-item {
	margin-right: 25px;
	position: relative;
}
.header-menu-box .header-navbar-menu .navbar .nav-item:last-child {
	margin-right: 0px;
}
.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link {
	padding: 10px 0px;
	font-size: 17px;
	font-family: "Mulish", sans-serif;
	font-weight: 600;
	color: #222222;
	display: block;
	text-transform: capitalize;
}
.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link.active,
.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link:hover {
	color: #f38a25;
}
.header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link {
	color: #222;
}
.header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link.active,
.header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link:hover {
	color: #f38a25;
}
.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link.change-color {
	-webkit-animation-name: color_change;
	        animation-name: color_change;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	        animation-direction: alternate;
}
.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link > i {
	font-size: 16px;
	position: relative;
	top: -2px;
}
.nav-item .dropdown-box {
	width: 240px;
	position: absolute;
	top: 100%;
	left: 0px;
	text-align: left;
	padding-top: 15px;
	visibility: visible;
	z-index: 99;
	display: none;
}
.nav-item .dropdown-box .dropdown-box-inner {
	background-color: #fff;
	padding: 0px;
	box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
}
.nav-item .dropdown-box li > a {
	display: block;
	padding: 8px 15px;
	color: #000;
	text-transform: capitalize;
	font-size: 16px;
	font-family: "Mulish", sans-serif;
	letter-spacing: 0.8px;
	font-weight: 500;
}
.nav-item .dropdown-box li > a:hover,
.nav-item .dropdown-box li > a.active {
	background-color: #f6f6f6;
}
.header-right-btn > ul {
	margin: 0px;
}
.header-right-btn > ul > li {
	float: left;
	margin-right: 10px;
}
.header-right-btn > ul > li:last-child {
	float: left;
	margin-right: 0px;
}
.header-right-btn > ul > li .login-text {
	padding: 11px 0px;
	font-size: 16px;
	font-family: "Mulish", sans-serif;
	font-weight: 800;
	color: #f38a25;
	display: block;
	margin-left: 4px;
	text-transform: uppercase;
}
.header-right-btn > ul > li > .theme-btn {
	padding: 12.5px 20px;
	font-size: 13px;
	font-weight: 600;
	background-color: #f38a25;
	color: #fff;
}
.header-right-btn > ul > li > .theme-btn:hover {
	color: #fff;
}
.header-main.fixed-header .header-right-btn > ul > li > .search-btn {
	background-color: rgb(57 72 104 / 8%);
	color: #f38a25;
}
.header-right-btn > ul > li > .search-btn {
	height: 45px;
	width: 45px;
	min-width: auto !important;
	padding: 0px;
	line-height: 46px;
	text-align: center;
	font-size: 18px;
	background-color: rgb(12 90 219 / 8%);
	color: #f38a25 !important;
}
.header-right-btn > ul > li > .search-btn::before,
.header-right-btn > ul > li > .search-btn::after {
	content: none;
}
.header-right-btn > ul > li > .search-btn > i {
	margin: 0px;
}
.header-right-btn > ul > li > .theme-btn.wallet-btn {
	background: #ffffff;
	color: #f38a25;
	box-shadow: none;
}
.header-right-btn > ul > li > .theme-btn.wallet-btn::before,
.header-right-btn > ul > li > .theme-btn.wallet-btn::after,
.slider-btn .theme-btn.sl-sale-btn::before,
.slider-btn .theme-btn.sl-sale-btn::after {
	content: none;
}
.user-navigation {
	background: rgba(255,255,255,0.6);
}
.user-navigation .navbar-light .navbar-nav .nav-link {
	color: #000;
}
.user-navigation .nav-item {padding: 0px 12px;}
.user-navigation .navbar-nav {
	margin: 0 auto;
}
/* header css */
/* slider css */
.slider-area {
	position: relative;
	padding-top: 76px;
}
.slider-area .slide-animate-img {
	position: absolute;
	top: 150px;
	left: -100px;
}
.slider-area .slide-animate-img.slide-animate-down {
	top: inherit;
	bottom: 60px;
	left: inherit;
	right: -100px;
}
.slider-area .slider-inner .row {
	display: table;
	height: calc(100vh - 76px);
	width: calc(100% - -15px);
}
.slider-area .slider-inner .row > div {
	display: table-cell;
	vertical-align: middle;
	width: 50%;
}
.slider-area .slider-information {
	max-width: 720px;
	margin: 0px auto 60px;
}
.slider-area .slider-information h1 {
	font-family: "Mulish", sans-serif;
	margin-bottom: 20px;
	color: #222;
	font-size: 56px;
	font-weight: 800;
	letter-spacing: 2px;
	line-height: 1.3;
}
.slider-area .slider-information .theme-description {
	color: #616161;
	line-height: 1.6;
	margin: 0px 0px 25px;
}
.slider-btn .theme-btn {
	margin-right: 15px;
	border: 2px solid #eee;
	background-color: #fff;
	color: #222;
}
.slider-btn .theme-btn:hover {
	color: #ffffff;
}
.slider-btn .theme-btn:last-child {
	margin-right: 0px;
}
.slider-btn .theme-btn.sl-sale-btn {
	border: 2px solid #f38a25;
	background-color: #f38a25;
	box-shadow: none;
	color: #fff;
}
.slider-area .slider-bottom-arrow {
	height: 60px;
	width: 60px;
	background-color: #24304c;
	font-size: 24px;
	line-height: 58px;
	text-align: center;
	border-radius: 100%;
	color: #fff;
	position: absolute;
	bottom: -28px;
	left: 0px;
	right: 0px;
	margin: 0px auto;
	border: 3px solid #fff;
	cursor: pointer;
}
/* slider css */
.earn-info h6 {
	margin-bottom: 5px;
	color: #f38a25;
	letter-spacing: 5px;
	font-size: 18px;
	font-weight: 800;
}
/* slider css */
.promotion-sreact-slider {
	background-color: #fff;
	padding: 0px 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 20px 50px 5px #e9eef7;
	-webkit-box-shadow: 0 20px 50px 5px #e9eef7;
	border-radius: 5px 5px;
}
.promotion-sreact-slider .promotion-sreact-box {
	position: relative;
	margin-top: 30px;
}
.promotion-sreact-img img {
	border-radius: 12px 12px;
}
.promotion-sreact-slider .promotion-sreact-box .promotion-sreact-info {
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 20px 20px 0px;
}
.promotion-sreact-slider .promotion-sreact-box > a {
	display: block;
}
.promotion-sreact-slider .promotion-sreact-box > a .theme-title {
	color: #fff;
	margin-bottom: 4px;
}
.promotion-sreact-slider .promotion-sreact-box > a .theme-description {
	color: #ccc;
}
.promotion-sreact-area .promotion-sreact-main {
	position: relative;
}
.promotion-sreact-main .shape2 {
	width: 176px;
	height: 176px;
	border-radius: 50%;
	opacity: 0.1;
	top: inherit;
	bottom: -60px;
	left: -80px;
	z-index: -1;
	background-color: red;
}
/* slider css */
/* singel about css */
.singel-about-box {
	margin-top: 30px;
}
.singel-about-box-item .singel-about-box-icon img {
	max-width: 58px;
	position: relative;
	top: -4px;
}
.singel-about-box-item .singel-about-box-info .theme-title {
	color: #222 !important;
	margin-bottom: 8px !important;
	font-weight: 700 !important;
	font-size: 20px !important;
}
.singel-about-box-item .singel-about-box-info .theme-description {
	margin: 0px;
}
.singel-about-box .singel-about-box-item {
	margin-bottom: 20px;
}
.singel-about-box .singel-about-box-item:last-child {
	margin-bottom: 0px;
}
.singel-about-box-item .singel-about-box-icon {
	height: 90px;
	width: 90px;
	line-height: 90px;
	font-size: 40px;
	color: #f38a25;
	position: relative;
	text-align: center;
	box-shadow: 0 20px 50px 5px #e9eef7;
	border-radius: 50%;
	z-index: 1;
	background: #fff;
	margin-right: 25px;
	float: left;
}
.singel-about-box-item .singel-about-box-info {
	width: calc(100% - 115px);
	float: left;
}
/* singel about css */
/* top ranked css */
.top-ranked-area .top-ranked-left {
	margin-top: 30px;
	position: relative;
}
.top-ranked-area .top-ranked-left .theme-title {
	background-color: rgb(34 34 34 / 60%);
	color: #fff;
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 12px 15px;
	text-align: center;
	border-radius: 0px 0px 15px 15px;
}
.top-ranked-area .top-ranked-left img {
	border-radius: 15px 15px;
}
.top-ranke-main .top-ranke-item {
	width: calc(33.33% - 30px);
	float: left;
	margin: 30px 15px 0px;
	position: relative;
	overflow: hidden;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	text-align: center;
	background: white;
	box-shadow: 0px 12px 30px 0px rgb(111 118 136 / 10%);
	padding: 60px 40px;
	margin-bottom: 40px;
	border-radius: 20px;
	position: relative;
	z-index: 3;
}
.top-ranke-main .top-ranke-item img {
	border-radius: 100%;
	max-width: 120px;
	margin: 0px auto 15px;
	display: block;
}
.most-viewed-items-area .row,
.top-selling-store .row {
	position: relative;
}
.most-viewed-items-area .bid-shape,
.top-selling-store .bid-shape {
	width: 60%;
	bottom: -30%;
	left: -15%;
	-webkit-transform: rotate(10deg);
	transform: rotate(10deg);
	position: absolute;
	z-index: -1;
	display: none;
}
.bid-shape path {
	fill: rgb(57 72 104 / 2%);
}
.most-viewed-items-area .bid-shape svg,
.top-selling-store .bid-shape svg {
	width: 80%;
}
.top-selling-store .bid-shape {
	left: inherit;
	right: -38%;
	bottom: -22%;
}
.top-selling-store .bid-shape svg {
	width: 60%;
}
/* top ranked css */
/* item group css */
.item-group {
	background-color: #fff;
	border: 1px solid #eaeff5;
	box-shadow: 0 20px 50px 5px #e9eef7;
	-webkit-box-shadow: 0 20px 50px 5px #e9eef7;
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
}
.item-group:hover {
	transition: all 0.4s ease-in-out;
	-webkit-transform: scale(1.06);
	transform: scale(1.06);
	box-shadow: 0 0px 30px 3px rgb(99 119 238 / 40%);
}
.item-group .item-group-content .item-group-avtar {
	width: 100px;
	margin: 0px auto 15px;
}
.item-group .theme-title {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.item-group .item-group-content .item-group-avtar img {
	padding: 4px;
	border-radius: 50px;
	width: 100px;
	height: 100px;
	object-fit: cover;
	border: 2px solid rgba(0, 0, 0, 0.03);
	box-sizing: border-box;
}
.item-group-content .theme-description {
	margin-bottom: 8px;
	font-size: 14px;
}
.item-group .item-price {
	font-size: 18px;
	font-weight: 800;
	margin: 0px;
	font-family: "Mulish", sans-serif;
	line-height: 1.4;
	color: #f38a25;
	margin-bottom: 15px;
}
.item-group .item-group-timer {
	margin-bottom: 18px;
}
.item-group .item-group-timer > ul {
	margin: 0px -15px;
	display: flex;
	justify-content: center;
}
.item-group.item-group-list .item-group-timer > ul {
	margin: 0px;
}
.item-group.item-group-list {
	padding: 30px 30px 30px;
}
.item-group .store-label {
	background-color: #f7f8f9;
	color: #222;
	display: inline-block;
	padding: 6px 20px;
	border-radius: 5px 0px 5px 0px;
	font-weight: 600;
	position: absolute;
	top: 4px;
	left: 4px;
	font-size: 14px;
}
.top-selling-store .item-group-content .theme-description {
	background-color: #f38a25;
	color: #fff;
	display: inline-block;
	padding: 5px 15px;
	border-radius: 5px 5px;
	margin-top: 12px;
	margin-bottom: 0px;
	font-family: "Mulish", sans-serif;
}
.item-group-content .theme-description span {
	font-weight: 600;
}
.item-group-btn .theme-btn {
	padding: 10px 25px;
	font-size: 14px;
	background: transparent;
	border: 1px solid #eee;
	color: #888;
}
.item-group-btn .item-detail-btn {
	height: 43px;
	width: 43px;
	display: inline-block;
	background-color: #eeeeee75;
	color: #a9a9a9;
	border-radius: 5px 5px;
	line-height: 44px;
	vertical-align: top;
	margin-left: 6px;
	font-size: 18px;
}
.item-group:hover .item-group-btn .theme-btn {
	background-color: #f38a25;
	border-color: #f38a25;
	color: #ffffff;
}
.item-group-content .items-like {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgb(57 72 104 / 4%);
	width: 38px;
	height: 38px;
	line-height: 40px;
	border-radius: 30px;
}
.item-group-content .items-like > a {
	color: #666666;
}
.item-group-content .items-like.like-active {
	background-color: #f38a25;
	color: #fff;
}
.item-group-content .items-like.like-active > a {
	color: #ffffff;
}
.item-group .item-group-text {
	padding: 15px;
	background: #f38a25;
	color: #fff;
}
.item-group .item-pending {
	position: absolute;
	right: 10px;
	top: 10px;
}
.browse-product-box {margin-top: 30px;}
.browse-product-box .item-group {
	padding: 50px 30px 30px;
	/* margin-top: 30px; */
	height: 100%;
}
/* item group css */
/* hot bids css */
.hot-bids-athore {
	display: flex;
	position: absolute;
	top: 15px;
	left: 20px;
}
.hot-bids-athore > a {
	margin: 0px 0px 0px -10px;
	padding: 0px;
	align-items: stretch;
	border-width: 0px;
	border-style: solid;
	border-color: rgb(4, 4, 5);
	display: flex;
	flex-basis: auto;
	flex-direction: column;
	flex-shrink: 0;
	min-height: 0px;
	min-width: 0px;
	max-width: 100%;
	z-index: 0;
	width: 25px;
}
.hot-bids-athore > a > img {
	border-radius: 100%;
}
/* hot bids css */
.item-group-btn .theme-btn::before,
.item-group-btn .theme-btn::after {
	content: none;
}
/* earn css */
.earn-area .earn-main-box {
	display: table;
	width: 100%;
}
.earn-area .earn-main-box > div {
	width: 50%;
	display: table-cell;
	vertical-align: middle;
	padding: 0px 15px;
}
.earn-area .earn-main-box .earn-images {
	text-align: center;
}
.earn-area .earn-main-box .earn-info h2 {
	font-size: 34px;
	line-height: 1.4;
	text-transform: capitalize;
	margin-bottom: 15px;
	font-weight: 800;
	color: #222;
	position: relative;
}
.earn-area .earn-main-box .theme-description {
	margin-bottom: 20px;
	color: #777777;
}
/* earn css */
/* mintable css */
.mintable-pro-area .earn-main-box .earn-btn .theme-btn {
	background-color: #fff;
	color: #f38a25;
}
.mintable-pro-area .earn-main-box .earn-btn .theme-btn:hover {
	color: #fff;
}
.mintable-pro-area .em_bar_bg {
	background-color: rgb(255 255 255 / 20%);
}
.mintable-pro-area .em_bar_bg::before {
	background: #ffffff;
}
/* mintable css */
/* earn css */
.earn-area.mintable-pro-area .earn-main-box .earn-info h2 {
	color: #fff;
}
.earn-area.mintable-pro-area .earn-main-box .earn-info .theme-description {
	color: #fff;
}
.earn-area .earn-main-box .earn-info .theme-title {
	margin: 0px 0px 12px;
	font-size: 22px;
	color: #f38a25;
	font-family: "Mulish", sans-serif;
	font-weight: 600;
}
.earn-area .earn-main-box .earn-info ul {
	margin: 0px 0px 20px 20px;
}
.earn-area .earn-main-box .earn-info ul li {
	color: #787878;
	padding-bottom: 8px;
	list-style: disc;
	font-weight: 600;
}
/* earn css */
/* top selling css */
.top-selling-store .item-group {
	padding: 0px;
}
.top-selling-store .item-group .item-group-content .item-group-avtar {
	width: 100%;
	margin: 0px;
}
.top-selling-store .item-group .item-group-content .item-group-avtar img {
	border-radius: 4px 4px;
	padding: 0px;
	border: 0px;
	width: auto;
	height: auto;
}
.top-selling-store .item-group .item-group-content {
	position: relative;
}
.top-selling-store .item-group .item-group-content::before {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	height: 0%;
	width: 100%;
	background: rgba(243, 138, 37, 0.75);
	transition: 0.5s;
}
.top-selling-store .item-group:hover .item-group-content::before {
	height: 100%;
}
.top-selling-store .item-group .selling-item-info {
	padding: 0px 15px;
	position: absolute;
	bottom: 30px;
	left: 0px;
	right: 0px;
	opacity: 0;
	transition: all 0.5s;
	visibility: hidden;
}
.top-selling-store .item-group .selling-item-info .theme-title {
	color: #fff;
}
.top-selling-store .item-group:hover .selling-item-info {
	opacity: 1;
	visibility: visible;
}
.top-selling-store .item-group .selling-item-info .theme-title > a {
	color: #fff;
}
/* top selling css */
/* mintable guides css */
.mintable-guides-box {
	margin: 60px 0;
}
.mintable-guides-box .mintable-guides-media {
	margin-bottom: 10px;
	font-size: 34px;
}
.mintable-guides-box .mintable-guides-media img {
	max-height: 180px;
}
.mintable-guides-box .mintable-guides-info .theme-title {
	margin-bottom: 4px;
}
.mintable-pro-area .earn-main-box {
	background-color: #f38a25;
	padding: 50px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 15px 15px;
	position: relative;
}
/* mintable guides css */
/* item group css */
.item-group.item-group-list:hover,
.view-store-item:hover {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
}
.item-group.item-group-list {
	text-align: left;
}
.item-group.item-group-list .item-group-content .item-group-info .theme-title {
	margin-bottom: 4px;
	font-size: 22px;
}
.item-group.item-group-list .item-group-content {
	display: table;
	width: 100%;
}
.item-group.item-group-list .item-group-content .item-group-media {
	width: 220px;
	display: table-cell;
	vertical-align: middle;
}
.item-group.item-group-list .item-group-content .item-group-info {
	width: calc(100% - 220px);
	display: table-cell;
	vertical-align: middle;
	padding-left: 20px;
}
.item-group.item-group-list .item-group-content .item-group-info .theme-title > a {
	color: #222;
}
.item-group-box > div {
	float: left;
	margin-right: 15px;
}
.item-group-box > div:last-child {
	margin-right: 0px;
}
.item-group-box > .item-group-btn .theme-btn {
	padding: 12px 30px;
}
.item-group-timer > ul {
	margin: 0px;
}
.item-group-timer > ul > li {
	float: left;
	margin-right: 10px;
	font-size: 11px !important;
	color: #888 !important;
	font-family: "Mulish", sans-serif;
	height: 45px;
	width: 60px;
	text-align: center;
	background-color: #f4f4f4;
	padding: 0px !important;
}
.item-group-timer > ul > li:last-child {
	float: left;
	margin-right: 0px;
}
.item-group-timer > ul > li > span {
	display: block;
	font-size: 16px;
	color: #222;
	font-weight: 600;
	margin-bottom: -4px;
	margin-top: 4px;
}
.item-group-box > .item-group-btn .theme-btn {
	padding: 12px 30px;
	background-color: #f38a25;
	color: #fff;
}
/* item group css */
/* bradcrumb css */
.bradcrumb-area {
	padding: 136px 0px 60px;
	position: relative;
	background-image: linear-gradient(
		to right,
		rgba(243, 138, 37, 0.85),
		rgba(243, 138, 37, 0.8),
		rgba(18, 85, 194, 0.7),
		rgba(34, 86, 172, 0.75),
		rgba(38, 85, 163, 0.75)
	);
}
.bradcrumb-area::before {
	content: "";
	background-image: url(/static/media/banner.6901927f.jpeg);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	opacity: 0.03;
}
.bradcrumb-main {
	display: table;
	width: 100%;
}
.bradcrumb-main > div {
	display: table-cell;
	vertical-align: middle;
}
.bradcrumb-main > .bradcrumb-title h1 {
	font-size: 32px;
	font-weight: 600;
	font-family: "Mulish", sans-serif;
	color: #fff;
	margin: 0px;
	letter-spacing: 1px;
}
.bradcrumb-main > .bradcrumb-right > ul {
	margin: 0px;
	float: right;
}
.bradcrumb-main > .bradcrumb-right > ul > li {
	font-size: 18px;
	float: left;
	margin-right: 15px;
	padding-right: 15px;
	font-family: "Mulish", sans-serif;
	text-transform: capitalize;
	font-weight: 500;
	position: relative;
	color: #fff;
	letter-spacing: 1px;
}
.bradcrumb-main > .bradcrumb-right > ul > li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.bradcrumb-main > .bradcrumb-right > ul > li::before {
	content: "/";
	color: #fff;
	position: absolute;
	top: 0px;
	right: -6px;
}
.bradcrumb-main > .bradcrumb-right > ul > li:last-child::before {
	content: none;
}
.bradcrumb-main > .bradcrumb-right > ul > li > a {
	color: #fff;
	font-weight: 500;
}
/* bradcrumb css */
/* browse product css */
.browse-product-filter .filter-box {
	background-color: #fff;
	padding: 20px 20px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	position: relative;
	margin-top: 30px;
}
.browse-product-filter .filter-box .theme-title {
	margin: -20px -20px 20px;
	padding: 15px 20px;
	border-bottom: 1px solid #eaeff5;
}
.browse-product-filter .filter-box .filter-menu ul {
	margin: 0px;
}
.browse-product-filter .filter-box .filter-menu ul > li {
	padding-bottom: 15px;
}
.browse-product-filter .filter-box .filter-menu ul > li:last-child {
	padding-bottom: 0px;
}
.browse-product-top {
	border: 1px solid #eee;
	padding: 12px 0px 12px 15px;
	margin-top: 30px;
}
.browse-product-top .row {
	display: table;
	width: calc(100% - -15px);
}
.browse-product-top .row > div {
	display: table-cell;
	vertical-align: middle;
	width: 50%;
}
.browse-product-left > p {
	font-size: 16px;
	font-family: "Mulish", sans-serif;
	margin: 0px;

	color: #222;
}
.browse-product-right > ul {
	margin: 0px;
	float: right;
	border: 0px;
}
.browse-product-right > ul > li {
	float: left;
	margin-right: 10px;
}
.browse-product-right > ul > li:last-child {
	margin-right: 0px;
}
.browse-product-right > ul > li > a.browse-store {
	display: block;
	padding: 10px 0px;
}
.browse-product-right > ul > li > a {
	font-size: 16px;
	color: #222;
	font-family: "Mulish", sans-serif;
}
.browse-product-right > ul > li .browse-list {
	height: 44px;
	width: 44px;
	display: block;
	background-color: #f8f8f8 !important;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	padding: 0px;
	border: 2px solid transparent;
	border-radius: 0px;
}
.browse-product-right > ul > li .browse-list.active {
	color: #f38a25 !important;
	border: 2px solid rgb(57 72 104 / 26%);
}
.browse-product-right > ul > li .default-switch .switch {
	top: 12px;
}
/* browse product css */
/* nft dao css */
.nft-dao-area .row {
	justify-content: center;
}
.nft-dao-area .nft-dao-box {
	margin-top: 60px;
	position: relative;
}
.nft-dao-area .nft-dao-box .nft-dao-icon {
	position: relative;
	z-index: 1;
	padding-top: 26px;
}
.nft-dao-area .nft-dao-box .nft-dao-info {
	padding-top: 50px;
}
.nft-dao-area .nft-dao-box .nft-dao-img {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
}
.nft-dao-area .nft-dao-box .nft-dao-icon img {
	max-width: 92px;
	display: block;
	margin: 10px auto;
}
.nft-dao-info .theme-title {
	font-size: 22px;
	margin-bottom: 6px;
}
/* nft dao css */
/* buy votes css */
.buy-votes-area .buy-vote-box {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
	max-width: 600px;
	margin: 30px auto 0px;
}
.buy-vote-terms .buy-vote-checkbox {
	position: relative;
}
.buy-vote-terms .buy-vote-checkbox label {
	text-transform: capitalize;
	text-align: left;
	padding-left: 30px;
}
.buy-vote-checkbox .styled-checkbox + label:before {
	position: absolute;
	top: 8px;
	left: 0px;
}
.buy-vote-checkbox .styled-checkbox + label::after {
	top: 17px;
}
.buy-vote-box .buy-vote-btn {
	margin-top: 30px;
}
.buy-vote-box .buy-vote-btn .theme-btn {
	width: 65%;
}
.buy-vote-box .buy-vote-form {
	margin: 0px -15px 20px;
}
.buy-vote-form .nft-input-box {
	width: calc(50% - 30px);
	float: left;
	margin: 30px 15px 0px;
}
/* buy votes css */
/* earn css */
.earn-btn .theme-btn {
	margin-right: 10px;
}
.earn-btn .theme-btn:last-child {
	margin-right: 0px;
}
.earn-btn .theme-btn.mints-btn {
	background-color: #00247e;
}
/* earn css */
/* proposals css */
.proposals-area .proposals-box-main {
	margin-top: 30px;
}
.proposals-header .theme-description span {
	font-weight: 600;
	color: #222;
}
.proposals-box-main .proposals-box {
	background-color: #fff;
	padding: 30px 0px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
	margin-top: 12px;
	display: table;
	width: 100%;
}
.proposals-box-main .proposals-box > div {
	display: table-cell;
	vertical-align: middle;
	width: 75%;
	padding: 0px 30px;
}
.proposals-box-main .proposals-box > .proposals-right {
	width: 25%;
	border: 1px solid rgb(66 140 212 / 15%);
	background-color: rgb(66 140 212 / 6%);
	border-right: 0px;
}
.proposals-right .proposals-date {
	display: block;
	font-size: 16px;

	font-style: italic;
	font-weight: 600;
	color: #f38a25;
}
.proposals-right .proposals-date > i {
	margin-right: 4px;
}
.proposals-right .proposals-id {
	display: block;
	font-size: 14px;
	color: #888;

	padding-left: 22px;
}
.proposals-box-main .proposals-box .theme-description span {
	font-weight: 600;
	color: #888;
}
.proposals-detail-area .proposal-back {
	margin-bottom: 15px;
}
.proposals-detail-area .proposal-back > a {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 600;
	color: #333;
}
.proposals-detail-area .proposal-back > a > i {
	margin-right: 4px;
}
.proposals-sidebar-box {
	background-color: #fff;
	padding: 15px 15px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
	margin-top: 30px;
}
.proposals-sidebar-box .theme-description {
	margin-bottom: 15px;
}
.proposals-area .proposals-sidebar {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
}
.proposals-detail-area .proposals-detail-box {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
}
.proposals-detail-area .proposals-detail-box h1 {
	font-size: 28px;
	font-family: "Mulish", sans-serif;
	font-weight: 500;
	margin-bottom: 8px;
}
.proposals-detail-area .proposals-detail-box .theme-description {
	margin-bottom: 15px;
}
.proposal-type .theme-title {
	margin-bottom: 6px;
}
.proposals-sidebar-box .theme-title {
	margin: -15px -15px 15px;
	padding: 15px 15px;
	border-bottom: 1px solid #eaeff5;
}
.proposals-sidebar-main > ul {
	margin: 0px;
}
.proposals-sidebar-main > ul > li {
	font-size: 15px;

	padding-bottom: 8px;
	font-family: "Mulish", sans-serif;
}
.proposals-sidebar-main > ul > li:last-child {
	font-size: 15px;

	padding-bottom: 0px;
}
.proposals-sidebar-main > ul > li > span {
	float: right;
	font-weight: 600;
}
.proposals-sidebar-main > ul > li > span > a {
	color: #f38a25;
}
.proposals-sidebar-main .proposal-btn {
	margin-top: 20px;
	text-align: center;
}
/* proposals css */
/* view store css */
.view-store-item {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
	margin-top: 30px;
}
.view-store-item .store-label {
	background-color: #f7f8f9;
	color: #f38a25;
	display: inline-block;
	padding: 6px 20px;
	border-radius: 0px 5px 0px 5px;
	font-weight: 600;
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 14px;
}
.view-store-box {
	display: table;
	width: 100%;
}
.view-store-box .view-store-images {
	width: 220px;
	display: table-cell;
	vertical-align: top;
}
.view-store-box .view-store-info {
	width: calc(100% - 220px);
	display: table-cell;
	vertical-align: top;
	padding-left: 20px;
}
.view-store-box .view-store-info .theme-title {
	margin-bottom: 6px;
}
.view-store-box .view-store-info ul {
	margin-bottom: 15px;
}
.view-store-box .view-store-info .store-ratting {
	margin-bottom: 8px;
	font-weight: 600;
}
.view-store-box .view-store-info ul li {
	font-size: 15px;
	padding-bottom: 6px;
	color: #888;
	font-family: "Mulish", sans-serif;
}
.view-store-box .view-store-info ul li:last-child {
	padding-bottom: 0px;
}
.store-ratting > i {
	margin-right: 4px;
	color: #f38a25;
}
.item-group-box .item-group-bid .theme-description {
	font-size: 12px;
}
.item-group-box .item-group-bid h2 {
	font-size: 22px;
	font-weight: 800;
	color: #f38a25;
	margin: 0px;
}
.item-group-box .item-group-bid {
	border-left: 1px solid #eaeff5;
	padding-left: 18px;
	margin-left: 5px;
}
.view-store-item .view-store-box .item-group-btn .theme-btn {
	background-color: #f38a25;
	color: #ffffff;
	text-align: center;
}
.view-store-item .browse-description {
	margin-top: 18px;
}
/* view store css */
/* pro stores css */
.pro-stores-area .pro-store-box {
	background-color: #fff;
	padding: 50px 30px;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
}
.pro-store-box .theme-title {
	margin-bottom: 6px;
	font-size: 22px;
	text-transform: uppercase;
}
.pro-store-box .theme-description {
	margin-bottom: 15px;
}
/* pro stores css */
/* pricing css */
.pricing-box {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	transition: 0.6s linear;
	margin-top: 30px;
}
.pricing-box .pricing-header {
	margin: -30px -30px 30px;
	background-color: #f38a2506;
	padding: 30px 30px;
}
.pricing-box .pricing-content .theme-description {
	margin-bottom: 15px;
}
.pricing-box .pricing-header h2 {
	font-size: 22px;
	font-family: "Mulish", sans-serif;
	text-transform: capitalize;
	margin-bottom: 0px;
	font-weight: 600;
}
.pricing-box .pricing-header .theme-description {
	font-size: 14px;
}
.pricing-box .pricing-content > ul {
	margin-bottom: 15px;
	padding-left: 20px;
}
.pricing-box .pricing-content > ul > li {
	font-size: 15px;
	font-weight: 500;
	color: #222;
	padding-bottom: 12px;
	line-height: 1.5;
	position: relative;

	list-style: disc;
}
.pricing-box .pricing-content > ul > li:last-child {
	padding-bottom: 0px;
}
.pricing-box .pricing-plan h2 {
	font-size: 26px;
	font-weight: 600;
	color: #f38a25;
	margin-bottom: 15px;
}
.pricing-box .pricing-plan {
	border-top: 1px solid #eaeff5;
	margin: 20px -30px 0px;
	padding: 20px 30px 0px;
}
.pricing-box .pricing-content {
	height: 420px;
}
/* pricing css */
.theme-btn.transparent-btn {
	border: 2px solid #eee;
	background-color: transparent;
	color: #888888;
}
.pricing-box .pricing-plan .theme-btn {
	width: 80%;
}
.contact-area .contact-box-main {
	background-color: #fff;
	padding: 20px 50px 50px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	transition: 0.7s;
	position: relative;
	margin-top: 30px;
}
/* user screen css */
.user-screen-box {
	display: table;
	width: 100%;
	height: 100vh;
}
.user-screen-box > .user-screen-item {
	display: table-cell;
	vertical-align: middle;
	width: 25%;
	position: relative;
}
.user-screen-box > .user-screen-item.screen-bg-image {
	width: 75%;
}
.user-screen-box > .user-screen-item.screen-form {
	padding: 50px 60px 130px 60px;
}
.user-screen-item .nft-links-btn {
	margin-top: 15px;
	position: relative;
}
.user-screen-item .nft-links-btn .styled-checkbox + label:before {
	position: absolute;
	top: 2px;
	left: 0px;
}
.user-screen-item .nft-links-btn .styled-checkbox + label:after {
	top: 11px;
}
.user-screen-item .nft-links-btn .styled-checkbox + label {
	padding-left: 32px;
}
.user-form .nft-links-btn > a {
	font-family: "Mulish", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	color: #f38a25;
	font-weight: 500;
}
.user-form .nft-links-btn > a.theme-btn {
	color: #fff;
	text-align: center;
}
.nft-links-btn .theme-btn {
	width: 100%;
	margin-bottom: 15px;
}
.user-screen-box > .user-screen-item.screen-bg-image {
	width: 75%;
	background-image: url(/static/media/banner.6901927f.jpeg);
	background-size: cover;
	vertical-align: bottom;
	padding-bottom: 150px;
}
.screen-bg-image .user-logo {
	position: relative;
	z-index: 1;
}
.user-screen-box > .user-screen-item.screen-bg-image::before {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#000000',GradientType=0 );
}
.user-logo img {
	max-width: 350px;
}
.user-screen-box > .user-screen-item .user-copyright {
	text-align: center;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgb(66 140 212 / 8%);
}
.user-screen-box > .user-screen-item .user-copyright > p {
	margin: 0px auto;
	max-width: 320px;
	padding: 15px 0px;
}
/* user screen css */
/* browse detail css */
.browse-detail-area {
	overflow-x: hidden;
}
.browse-detail-area .browse-detail-images {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
}
.browse-thumblain > ul {
	margin: 0px -8px 15px;
	/* width: 480px; */
	padding: 8px 0;
}
.browse-thumblain > ul > li {
	width: calc(25% - 16px);
	float: left;
	margin: 8px;
	margin-top: 12px;
}
.browse-detail-info h1 {
	color: #222;
	font-size: 32px;
	font-family: "Mulish", sans-serif;
	font-weight: 700;
	margin-bottom: 15px;
	line-height: 1.4;
}
.browse-detail-info .store-ratting {
	margin-bottom: 12px;
}
.browse-detail-info .theme-title {
	color: #666;
	margin-bottom: 8px;
}
.browse-detail-info .theme-description {
	margin-bottom: 12px;
	text-align: justify;
}
.text-center .theme-description {
	text-align: center;
}
.browse-detail-info .browse-bid-detail .browse-bid-box {
	padding: 20px 20px;
}
.browse-bid-detail .browse-bid-box h2 {
	color: #222;
	font-size: 22px;
	line-height: 1.4;
	font-weight: 700;
	margin-bottom: 0px;
	text-transform: uppercase;
	font-family: "Mulish", sans-serif;
}
.browse-bid-detail .browse-bid-box h2 span {
	color: #f38a25;
}
.browse-bid-detail .browse-bid-box .theme-description {
	font-size: 16px;
	margin: 0px;
}
.browse-bid-detail .browse-bid-box .nft-input-box {
	margin-top: 5px;
	margin-bottom: 15px;
}
.browse-bid-btn .theme-btn {
	width: 100%;
	color: #888888 !important;
}
.browse-bid-btn .theme-btn::before,
.browse-bid-btn .theme-btn::after {
	content: none;
}
.browse-bid-detail .browse-bid-box .nft-input-box .theme-description {
	margin: 0px;
	position: absolute;
	top: 7px;
	left: 0px;
	font-size: 18px;
	font-weight: 600;
}
.browse-bid-detail .browse-bid-box .nft-input-box .nft-input {
	padding-left: 20px;
}
.browse-bid-footer {
	padding: 0px 15px 20px;
}
.browse-bid-detail .browse-buy-btn {
	margin-bottom: 20px;
	text-align: center;
}
.browse-bid-detail .browse-buy-btn {
	margin-bottom: 20px;
	text-align: center;
}
.browse-bid-detail .browse-buy-btn .theme-btn {
	width: 80%;
}
.browse-bid-footer .theme-description {
	margin: 0px;
	font-size: 16px;
}
.browse-detail-info .browse-bid-detail {
	background-color: #fff;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	margin-bottom: 15px;
	position: relative;
}
.browse-detail-info .browse-bid-detail .browse-refresh {
	width: 38px;
	height: 38px;
	border: 1px solid #eee;
	color: #666;
	border-radius: 4px 4px;
	text-align: center;
	line-height: 35px;
	font-size: 15px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}
.browse-detail-info .browse-detail-timer {
	display: table;
	background-color: rgb(57 72 104 / 4%);
	padding: 20px 20px;
}
.browse-detail-info .browse-detail-timer > div {
	display: table-cell;
	width: 50%;
	vertical-align: middle;
	padding: 0px 30px;
	border-right: 1px solid #e2e6f0;
}
.browse-detail-info .browse-detail-timer .browse-time-left {
	padding-left: 10px;
}
.browse-detail-info .browse-detail-timer > div:last-child {
	border-right: 0px;
	padding-right: 0px;
}
.browse-time-left ul {
	margin: 0px;
	display: flex;
	justify-content: center;
}
.browse-time-left ul > li {
	float: left;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: #888;
}
.browse-time-left ul > li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.browse-time-left ul > li > span {
	font-size: 26px;
	font-weight: 700;
	display: block;
	font-family: "Mulish", sans-serif;
	line-height: normal;
	color: #222;
}
/* browse detail css */
/* starting bid css */
.starting-bid ul {
	margin-bottom: 15px;
}
.starting-bid ul li {
	padding-bottom: 6px;
	font-family: "Mulish", sans-serif;
}
.starting-bid ul li > span {
	font-weight: 600;
}
.starting-bid ul li:last-child {
	padding-bottom: 0px;
}
/* starting bid css */
.browse-detail-info .browse-detail-timer .browse-time-right h2 {
	font-size: 15px;
	margin-bottom: 0px;
	font-weight: 500;
	font-family: "Mulish", sans-serif;
	color: #666;
	text-transform: uppercase;
	text-align: center;
	line-height: 1.4;
}
.browse-tag ul {
	margin: 0px -5px;
	display: grid;
    grid-template-columns: 1fr 1fr;
}
.browse-tag ul > li {
	float: left;
	margin: 5px;
	background-color: #eee;
	font-size: 15px;
	padding: 8px 15px;
	line-height: normal;
	color: #616161;
	cursor: pointer;
    text-align: center;
    border-radius: 5px;
}
.browse-tag ul a {
	color: inherit;
}
.browse-vote-btn .theme-btn {
	width: 60%;
}
/* faq css */
.faq-area .section-title {
	margin-bottom: 15px;
}
.faq-box-main .faq-box {
	margin-top: 15px;
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
}
.faq-box-main .faq-box .theme-title {
	font-size: 18px;
	position: relative;
	padding-left: 25px;
	border-bottom: 1px solid #eaeff5;
	padding-bottom: 12px;
	margin-bottom: 12px;
}
.faq-box-main .faq-box .theme-title > i {
	margin-right: 6px;
	position: absolute;
	top: 4px;
	left: 0px;
	color: #f38a25;
	font-size: 18px;
}
.faq-box-main .faq-box .theme-description {
	margin-bottom: 10px;
}
.faq-box-main .faq-box .theme-description:last-child {
	margin-bottom: 0px;
}
/* faq css */
/* discover css */
.discover-area .discover-box-images {
	position: relative;
}
.discover-img-box .discover-title {
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 1;
}
.discover-main-box {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	margin-top: 30px;
}
.discover-main-box > .row {
	display: table;
	width: calc(100% - -15px);
}
.discover-main-box > .row > div {
	display: table-cell;
	vertical-align: top;
}
.discover-box-info-top .theme-description {
	margin-bottom: 14px;
}
.discover-box-info-top .theme-title {
	color: #222;
	font-weight: 600;
}
.discover-area .discover-main-box .discover-left {
	width: 41.666667%;
}
.discover-area .discover-box-images::before {
	content: "";
	position: absolute;
	top: 0;
	padding: 20px;
	left: 0;
	right: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#000000',GradientType=0 );
	bottom: 0;
}
.discover-img-box .discover-title .theme-title {
	font-size: 22px;
	color: #fff;
}
.discover-img-box .discover-title .theme-description {
	color: #ddd;
}
.discover-box-info-top h2 {
	color: #222;
	font-size: 32px;
	font-family: "Mulish", sans-serif;
	font-weight: 700;
	margin-bottom: 15px;
	line-height: 1.4;
}
.discover-box-info-top .theme-title {
	font-weight: 600;
	margin-bottom: 6px;
	color: #222;
}
.discover-box-info-top ul {
	margin: 0px;
}
.discover-box-info-top ul li {
	font-size: 15px;
	font-family: "Mulish", sans-serif;
	margin-bottom: 8px;
	color: #222;
}
.discover-box-info-top ul li:last-child {
	margin-bottom: 0px;
}
/* discover css */
/* nft filter css */
.nft-filter .nav > li {
	margin: 5px 5px;
}
.nft-filter .nav > li > a {
	background: #f2f4ff;
	color: #616161;
	border: medium none;
	font-size: 16px;
	line-height: 1;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	text-transform: capitalize;
	padding: 12px 16px;
	font-weight: 500;
	letter-spacing: 1px;
	position: relative;
	margin: 0 2px;
	margin-bottom: 7px;
	display: inline-block;
	border-radius: 5px;
}
.nft-filter .nav > li > a.active {
	background-color: #f38a25;
	color: #fff;
}
.discover-area .discover-rate-box {
	margin-top: 30px;
}
.discover-rate-box h2 {
	font-size: 22px;
	font-weight: 500;
	color: #222;
	font-family: "Mulish", sans-serif;
	margin: 15px 0px;
}
.discover-area .discover-rate-box .discover-rate-tab .nft-filter {
	margin: 0px -5px 15px;
}
.discover-process .discover-process-box {
	display: flex;
	width: 100%;
	margin-top: 12px;
}
.discover-process .discover-process-box span {
	font-size: 14px;
	display: inline-block;

	color: #888;
}
.discover-process .discover-process-box .discover-process-bar {
	width: 65%;
	margin: 8px 15px 0px;
	height: 8px;
	background-color: #eee;
	border-radius: 6px 6px;
	position: relative;
}
.discover-process .discover-process-box .discover-process-bar .progress-bar {
	height: 8px;
	border-radius: 6px 6px;
	width: 99%;
	background-color: #f38a25;
}
.discover-tab-pane-box {
	display: table;
	width: 100%;
}
.discover-tab-pane-box > div {
	display: table-cell;
	vertical-align: middle;
}
.discover-tab-pane-box .discover-tab-left {
	width: 75%;
}
.discover-tab-pane-box .discover-tab-right {
	width: 25%;
}
.discover-tab-pane-box .discover-tab-right .discover-tab-buy {
	background-color: rgb(242 244 255);
	padding: 30px 30px;
	border-radius: 6px 6px;
}
.discover-rate-tab .discover-bottom {
	margin-top: 15px;
	border-top: 1px solid #eaeff5;
	padding-top: 15px;
	text-align: right;
	margin-bottom: -10px;
}
/* nft filter css */
/* user policy css */
.user-policy-box .theme-description {
	margin-bottom: 15px;
}
.user-policy-main h2 {
	font-size: 28px;
	font-weight: 800;
	margin-bottom: 15px;
}
.user-policy-box .privacy-left-box {
	padding-left: 30px;
}
.user-policy-box .theme-title {
	font-size: 22px;
	margin: 15px 0px;
}
.user-policy-box ul {
	margin-bottom: 15px;
	padding-left: 35px;
}
.user-policy-box ul li {
	font-size: 16px;
	color: #222;
	line-height: 1.6;
	margin: 0px;
	list-style: disc;
	padding-bottom: 6px;
	font-family: "Mulish", sans-serif;
}
.user-policy-box ul li:last-child {
	padding-bottom: 6px;
}
/* user policy css */
/* search css */
.search-active {
	overflow: hidden;
}
.search-active::before {
	content: "";
	height: 100%;
	width: 100%;
	background-color: #fff;
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 999;
	opacity: 0.99;
}
.header-search-box {
	display: table;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	max-width: 767px;
	margin: 0px auto;
	z-index: 999;
	display: none;
}
.header-search-box .header-search-box-main {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	padding: 0px 30px;
}
.header-search-box .header-search-box-main .nft-input {
	font-size: 20px;
}
.header-search-box .header-search-box-main .theme-btn {
	position: absolute;
	top: -6px;
	right: 0px;
}
.show-result-box h1 {
	font-size: 30px;
	font-weight: 500;
	font-family: "Mulish", sans-serif;
	text-transform: capitalize;
	color: #222;
	margin: 0px;
}
.header-search-box .header-search-box-main .nft-input {
	padding-right: 145px;
}
.search-close {
	position: fixed;
	top: 15px;
	right: 25px;
	z-index: 999;
	font-size: 32px;
	color: #f38a25;
	cursor: pointer;
	display: none;
}
.search-active .header-search-box {
	display: table;
}
.search-active .search-close {
	display: block;
}
/* search css */
/* list select css */
.list-select-item > li {
	float: left;
	margin-right: 8px;
	margin-bottom: 8px;
}
.list-select-item > li:last-child {
	margin-right: 0px;
}
.list-select-item > li > span {
	display: inline-block;
	border: 1px solid #eee;
	padding: 4px 15px;
	border-radius: 4px 4px;
	font-size: 15px;
	color: #818c95;
	cursor: pointer;
}
.list-select-item > li > span.active,
.list-select-item > li > span:hover {
	background-color: #f38a25;
	color: #fff;
	border-color: #f38a25;
}
/* list select css */
/* min item css */
.min-item-area {
	padding-top: 176px;
}
.unverfied-account .min-item-area {
	padding-top: 226px;
}
.mint-item-popup .modal-dialog {
	max-width: 720px;
}
.min-item-box {
	max-width: 740px;
	margin: 0px auto;
}
.min-item-box .min-item-item {
	width: calc(50% - 30px);
	float: left;
	margin: 30px 15px 0px;
	background-color: #fff;
	padding: 50px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 3px 9px rgb(158 158 158 / 20%), 0 14px 0 -7px #fff, 0 14px 9px -6px rgb(171 188 203 / 20%),
		0 25px 0 -12px #fff, 0 25px 9px -11px rgb(189 200 209 / 20%);
	-webkit-box-shadow: 0 3px 9px rgb(158 158 158 / 20%), 0 14px 0 -7px #fff, 0 14px 9px -6px rgb(171 188 203 / 20%),
		0 25px 0 -12px #fff, 0 25px 9px -11px rgb(189 200 209 / 20%);
	border-radius: 6px;
	cursor: pointer;
	height: 246px;
}
.min-item-item .min-item-ico {
	margin-bottom: 12px;
}
.min-item-item .min-item-ico img {
	max-width: 80px;
}
.min-item-box .min-item-item .theme-title {
	font-size: 20px;
	color: #222;
}
.blockchain-popup-box .nft-input-box {
	margin-top: 30px;
}
/* min item css */
/* traditional gasless css */
.traditional-gasless-item .traditional-gasless-icon img {
	max-width: 80px;
}
.traditional-gasless-main .traditional-gasless-item {
	width: 100%;
	float: left;
	margin-top: 30px;
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
}
.traditional-gasless-main .traditional-gasless-item .theme-title {
	margin-bottom: 15px;
}
.traditional-gasless-main .traditional-gasless-item .traditional-gasless-icon {
	margin-bottom: 15px;
}
.traditional-gasless-main .default-switch {
	text-align: right;
	margin-top: 30px;
	margin-bottom: 12px;
}
/* traditional gasless css */
.default-switch .switch-label {
	font-size: 15px;
	display: inline-block;
	vertical-align: text-bottom;
	font-weight: 400;
	font-family: "Mulish", sans-serif;
	margin: 0px 8px;
	color: #666;
}
.gasless-area .default-switch {
	margin-top: 30px;
	text-align: right;
}
.mint-item-main-box {
	background-color: #fff;
	padding: 10px 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	margin-top: 12px;
}
.mint-item-form .theme-input-box {
	margin-top: 20px;
	position: relative;
}
.data-value-btn > button {
	background-color: #eff6fc;
	font-size: 12px;
	border: 0px;
	color: #f38a25;
	padding: 8px 10px;
	line-height: normal;
	cursor: pointer;
	outline: 0 !important;
}
.theme-input-box .data-value-btn {
	position: absolute;
	bottom: 10px;
	right: 10px;
}
.mint-item-main-box .theme-description {
	font-size: 15px;
}
.upload-images-box {
	margin: 15px -15px 0px;
}
.upload-images-box .upload-images-item {
	width: calc(80px - -15px);
	text-align: center;
	float: left;
	margin-right: 10px;
}
.upload-images-preview {
	display: inline-block;
}
.upload-images-preview span {
	height: 92px;
	width: 92px;
	display: inline-block;
	box-shadow: 0px 0px 15px #eee;
	padding: 5px 5px;
	margin-left: 12px;
	position: relative;
	cursor: pointer;
}
.upload-images-preview span > i {
	height: 20px;
	width: 20px;
	background-color: red;
	border-radius: 100%;
	font-size: 10px;
	text-align: center;
	line-height: 20px;
	color: #fff;
	position: absolute;
	top: -6px;
	right: -8px;
}
.upload-images-preview span img {
	max-width: 100%;
}
.upload-images-box .upload-images-item .theme-description {
	line-height: normal;
}
.mint-item-form .upload-images-box {
	margin: 15px 0px 0px;
	border: 1px solid #eaeff5;
	padding: 15px 15px;
	border-radius: 6px 6px;
}
.upload-images-box .upload-images-item > i {
	width: 65px;
	height: 65px;
	display: block;
	border: 2px dashed #eaeff5;
	font-size: 26px;
	color: #f38a25;
	text-align: center;
	line-height: 58px;
	cursor: pointer;
	margin: 0px auto 8px;
}
.mint-item-form .theme-input-box .cke_bottom {
	display: none;
}
.mint-item-form .theme-input-box .cke_top {
	background: transparent;
	border-color: #eaeff5;
	padding: 8px 8px 4px 8px;
}
.mint-item-form .theme-input-box .cke_chrome {
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
}
.Listing-checkbox label {
	margin: 12px 0px 0px;
	font-weight: 400;
	color: #666;
}
.theme-form-title {
	margin: 40px 0px 30px;
}
.theme-form-title .theme-title {
	position: relative;
}
.theme-form-title .theme-title::before {
	content: "";
	border: 1px solid rgb(12 90 219 / 20%);;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	padding: 1px;
}
.theme-form-title .theme-title > span {
	display: inline-block;
	border: 1px solid #e1e1e1;
	padding: 8px 30px;
	background-color: #e1e1e1;
	position: relative;
	z-index: 1;
}
.theme-box-center .row {
	display: table;
	width: calc(100% - -15px);
}
.theme-box-center .row > div {
	width: 50%;
	display: table-cell;
	vertical-align: bottom;
}
/* fix price css */
.fix-price-value .theme-title {
	font-size: 16px;
}
.fix-price-value .theme-description {
	font-size: 14px;
}
.fixed-price-tab-box .tab-content {
	padding: 0px 25px 25px;
	margin-top: 12px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
}
.fixed-price-tab-box .theme-fix-btn {
	margin-top: 18px;
}
/* fix price css */
/* wallet detected css */
.wallet-detected-box {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgb(255 255 255 / 92%);
	z-index: 99;
}
.wallet-detected-box.wallet-detected-remove {
	display: none;
}
.wallet-detected-box .wallet-detected-item {
	max-width: 520px;
	margin: 0px auto;
	padding: 30px 30px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	position: absolute;
	top: 50%;
	left: 0px;
	right: 0px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}
.wallet-detected-box .wallet-detected-item .wallet-close-btn {
	font-size: 26px;
	color: #f38a25;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}
.wallet-detected-media img {
	max-width: 200px;
}
.wallet-detected-box .wallet-detected-item .wallet-detected-media {
	margin-bottom: 20px;
}
.wallet-detected-box .wallet-detected-item .wallet-detected-info .theme-title {
	margin-bottom: 6px;
	font-size: 25px;
}
.wallet-detected-box .wallet-detected-item .wallet-detected-info .theme-description {
	margin-bottom: 15px;
}
/* wallet detected css */
/* checkout css */
.checkout-box-main .checkout-item {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	box-shadow: 0 20px 50px 5px #e9eef7;
	-webkit-box-shadow: 0 20px 50px 5px #e9eef7;
	border-radius: 6px;
	position: relative;
	margin-top: 30px;
}
.checkout-item .checkout-item-media {
	width: 120px;
	float: left;
}
.checkout-item .checkout-item-info {
	width: calc(100% - 140px);
	float: left;
	margin-left: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #f3f6fb;
}
.checkout-item .checkout-item-info .checkout-info-box {
	width: 33.33%;
	float: left;
	text-align: center;
}
.checkout-item .checkout-item-info .checkout-info-box .theme-description {
	font-size: 14px;
}
.checkout-box-main .checkout-item .checkout-price-ratting {
	position: absolute;
	bottom: 35px;
	right: 30px;
}
.checkout-price-ratting ul {
	margin: 0px;
}
.checkout-price-ratting ul > li {
	float: left;
	padding-right: 15px;
}
.checkout-price-ratting ul > li:last-child {
	padding-right: 0px;
}
.checkout-price-ratting ul > li > h2 {
	font-size: 22px;
	font-weight: 700;
	color: #222;
	margin: 0px;
}
/* checkout css */
.price-filter-box h2 {
	font-size: 24px;
	font-weight: 700;
	color: #222;
	margin: 0px 0px 2px;
}
.price-filter-box .price-small {
	color: #f38a25;
	font-weight: 600;
}
.filter-menu .price-filter-box {
	margin-bottom: 20px;
}
.filter-menu .price-filter-box .theme-title {
	margin: 0px;
	padding: 0px;
	border: 0px;
}
.minting-plan-main {
	margin: -15px -15px 0px;
}
.minting-plan-main .minting-plan-box {
	background-color: #fff;
	padding: 20px 20px;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	border-radius: 6px;
	width: calc(25% - 16px);
	text-align: center;
	float: left;
	margin: 15px 8px 0px;
	position: relative;
	overflow: hidden;
}
.minting-plan-main .minting-plan-box .minting-label {
	height: 80px;
	width: 80px;
	background-color: #f38a25;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: absolute;
	top: -45px;
	right: -45px;
}
.minting-plan-main .minting-plan-box .minting-label > span {
	position: absolute;
	top: 60px;
	left: 30px;
	font-size: 11px;
	font-weight: 800;
	color: #fff;
}
.minting-plan-main .minting-plan-box .minting-plan-top {
	margin-bottom: 6px;
}
.minting-plan-main .minting-plan-box .minting-plan-bottom {
	margin-top: 8px;
}
.minting-plan-main .minting-plan-box h2 {
	font-size: 28px;
	font-weight: 800;
	color: #222;
}
.price-range-slider {
	width: 100%;
	padding: 20px 0px 30px;
}
.price-range-slider .range-value {
	margin: 0;
}
.price-range-slider .range-value input {
	width: 100%;
	background: none;
	color: #000;
	font-size: 16px;
	font-weight: initial;
	box-shadow: none;
	border: none;
	margin: 0px 0 20px 0;
}
.price-range-slider .range-bar {
	border: none;
	background: #eee;
	border-color: #eee !important;
	height: 3px;
	width: 96%;
	margin-left: 8px;
}
.price-range-slider .range-bar .ui-slider-range {
	background: #f38a25;
}
.price-range-slider .range-bar .ui-slider-handle {
	border: none;
	border-radius: 25px;
	background: #fff;
	border: 2px solid #f38a25;
	height: 17px;
	width: 17px;
	top: -0.52em;
	cursor: pointer;
	outline: 0;
}
.price-range-slider .range-bar .ui-slider-handle + span {
	background: #f38a25;
}
.create-store-popup .create-store-box {
	background-color: #fff;
	padding: 30px 30px;
	border: 1px solid #eaeff5;
	border-radius: 6px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: -10px;
}
.create-store-popup .create-store-box span {
	height: 50px;
	width: 50px;
	display: block;
	background-color: #f38a25;
	font-size: 24px;
	line-height: 50px;
	color: #fff;
	font-weight: 800;
	border-radius: 100%;
	margin: 0px auto 15px;
}
/* order sucsess css */
.order-sucsess-area .order-sucsess-images {
	margin-bottom: 30px;
}
.order-sucsess-area .order-sucsess-images img {
	max-width: 450px;
}
.order-sucsess-area .order-sucsess-info h1 {
	font-size: 34px;
	line-height: 1.4;
	text-transform: capitalize;
	margin-bottom: 5px;
	font-weight: 800;
	color: #222;
	position: relative;
}
.order-sucsess-area .order-sucsess-info .theme-description {
	max-width: 600px;
	margin: 0px auto;
	font-size: 18px;
}
/* order sucsess css */
/* footer css */
.footer-main {
	background-color: #111a3a;
	position: relative;
}
.footer-main::before {
	content: "";
	background-image: url(/static/media/world_map.f5878b81.png);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	opacity: 1;
}
.footer-main .newsletter-box {
	-webkit-transform: translateY(-100px);
	        transform: translateY(-100px);
}
.footer-top {
	padding: 60px 0px 80px;
	position: relative;
}
.footer-top .footer-widget {
	margin-top: 30px;
}
.footer-top .footer-widget img {
	max-width: 200px;
}
.footer-widget .theme-description {
	margin-top: 15px;
	font-size: 15px;
	color: #fff;
}
.footer-widget .theme-title {
	text-transform: capitalize;
	font-size: 20px;
	margin-bottom: 18px;
	color: #fff;
	margin-bottom: 12px;
}
.footer-widget .footer-menu-services .menu-service-menu,
.footer-location .location-box {
	margin: 0px;
}
.footer-widget .footer-menu-services .menu-service-menu > li {
	padding-bottom: 8px;
}
.footer-widget .footer-menu-services .menu-service-menu > li:last-child,
.footer-location .location-box > li:last-child {
	padding-bottom: 0px;
}
.footer-widget .footer-menu-services .menu-service-menu > li > a {
	font-size: 16px;
	color: #ccc;
	position: relative;
	text-transform: capitalize;
	font-weight: 600;
}
.footer-location .location-box > li {
	padding-bottom: 12px;
	position: relative;
	padding-left: 25px;
}
.footer-location .location-box > li > i {
	font-size: 16px;
	position: absolute;
	top: 2px;
	left: 0px;
	color: #f38a25;
}
.footer-location .location-box > li > a {
	font-size: 16px;
	color: #222;
	position: relative;
}
.footer-top .footer-widget .footer-links {
	margin-top: 15px;
}
.footer-icon-box {
	float: left;
	margin-right: 10px;
	position: relative;
}
.footer-icon-box:last-child {
	margin-right: 0px;
}
.footer-icon-box a {
	font-size: 18px;
	width: 40px;
	height: 40px;
	color: #0063c1;
	display: block;
	background: #fff;
	text-align: center;
	line-height: 40px;
}
.footer-copyright {
	padding: 20px 0px;
	/* background-color: #131e43; */
	border-top: 1px solid #212c56;
}
.footer-copyright .copyright-text p {
	font-size: 16px;
	font-family: "Mulish", sans-serif;
	margin: 0px;
	color: #fff;
	font-weight: 400;
	letter-spacing: 1px;
}
.footer-copyright .copyright-text p > a {
	color: #fff;
	font-weight: 800;
	text-transform: capitalize;
}
/* footer css */
/* userstore css */
.user-store-info ul {
    margin: 0;
    padding-top: 15px;
    padding-left: 20px;
}
.user-store-info ul li {
    font-size: 15px;
    padding-bottom: 10px;
    list-style: disc;
    letter-spacing: 0.5px;
    word-break: break-word;
}
.user-store-info ul li:last-child {
    padding-bottom: 0px;
}
.user-store-info ul li span {
    font-weight: 800;
}
/* keyframes css */
@-webkit-keyframes slide-down {
	0% {
		opacity: 0;
		transform: translateY(-100%);
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
	}
	100% {
		opacity: 0.9;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
}
@keyframes slide-down {
	0% {
		opacity: 0;
		transform: translateY(-100%);
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
	}
	100% {
		opacity: 0.9;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
}
@-webkit-keyframes color_change {
	from {
		color: #f38a25;
	}
	to {
		color: #f38a25;
	}
}
@keyframes color_change {
	from {
		color: #f38a25;
	}
	to {
		color: #f38a25;
	}
}
@-webkit-keyframes MOVE-BG {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(88px);
		transform: translateX(88px);
	}
}

@keyframes MOVE-BG {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(88px);
		transform: translateX(88px);
	}
}
/* keyframes css */
/* activity css */
.css-1zj86l {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	display: grid;
	grid-gap: 3px;
	grid-gap: 10px;
	gap: 10px;
}
.css-zrdji6 {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	padding: 20px 24px;
	border-radius: 12px;
	align-items: center;
	background-color: #ffffff;
	background-color: var(--theme-ui-colors-white-100, #ffffff);
	position: relative;
}

.css-zrdji6::before {
	content: "";
	box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;
	border-radius: 12px;
	position: absolute;
	inset: 0px;
	z-index: -2;
}

.css-942keq {
	box-sizing: border-box;
	margin: 0px;
	min-width: 0px;
	display: flex;
	flex: 1 1;
	flex-direction: row;
	grid-gap: 0px;
	gap: 0px;
}

.css-12hibeq {
	display: inline-block;
}

.css-5avtf0,
.css-w6k6xy {
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	display: flex;
}

.css-w6k6xy {
	flex: 1 1;
	flex-wrap: wrap;
	order: 1;
	margin-right: 24px;
}

.css-5avtf0 {
	order: 2;
	align-items: center;
}

.css-68da1 {
	font-weight: 700;
}
.css-1btdx2y {
	margin-left: 5px;
	display: inline-block;
}

.css-1lrq472 {
	text-align: right;
}
/* user bar ******/
.user-panel-header-top-right {
	margin-left: 10px;
}
.user-panel-profile h3 {
	font-size: 16px;
	margin: 0;
	font-weight: 500;
	font-family: Mulish, sans-serif;
}
.user-panel-profile h3 > span {
	font-weight: 700;
	text-decoration: underline;
}
.user-panel-profile h3 > a > span {
	height: 35px;
	width: 35px;
	display: inline-block;
	background-color: #CCC;
	font-size: 18px;
	border-radius: 100%;
	line-height: 34px;
	text-align: center;
	color: #f38a25;
	margin-left: 3px;
	font-weight: 800;
}
.user-panel-header-top-right .user-panel-profile {
	float: right;
	position: relative;
}
.user-panel-profile .profile-dropdown {
	width: 220px;
	position: absolute;
	top: 100%;
	right: 0;
	text-align: left;
	z-index: 99;
	margin-top: 15px;
	background-color: #fff;
	background-color: #fff;
	border: 1px solid #eaeff5;
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	-webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
}
.user-panel-profile .profile-dropdown::before {
	content: "";
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #fff;
	position: absolute;
	top: -8px;
	right: 6px;
}
.user-panel-profile a {
	cursor: pointer;
}
.user-panel-profile .profile-dropdown .user-links {
	margin: 0;
}
.user-panel-profile .profile-dropdown .user-links > li > a,
.user-panel-profile .profile-dropdown .user-links > li > p {
	display: block;
	padding: 8px 15px;
	color: #000;
	text-transform: capitalize;
	font-size: 16px;
	font-family: Mulish, sans-serif;
	font-weight: 500;
}
.user-panel-profile .profile-dropdown .user-links > li > a.user-logout {
	background-color: #f38a25 !important;
	color: #fff !important;
}
.user-panel-profile .profile-dropdown .user-links > li > a.active,
.user-panel-profile .profile-dropdown .user-links > li > a:hover,
.user-panel-profile .profile-dropdown .user-links > li > p {
	background-color: #f4f4f4;
}
.user-panel-header-area .user-panel-header-bottom {
	box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
	background-color: #fff;
	padding: 8px 0;
	position: relative;
}
/* dark mode css ***********/
.shadow-dark {
	color: #989898;
}
.shadow-dark .slider-area {
	background: transparent;
}
.shadow-dark .theme-container {
	background-color: #313850;
	color: #999;
}
.shadow-dark .dropdown-custom .dropdown-select-custom,
.shadow-dark .dropdown-menu-custom {
	background-color: #dddddd;
	border-color: #dddddd;
	box-shadow: none;
	color: #222;
}
.shadow-dark .dropdown-custom .dropdown-select-custom::before,
.shadow-dark .dropdown-menu-custom li > a {
	color: #222222;
}
.shadow-dark .dropdown-menu-custom li {
	border-top: solid 1px #868686;
}
.shadow-dark,
.shadow-dark .footer-main,
.shadow-dark .search-active::before,
.shadow-dark .modal-backdrop,
.shadow-dark .footer-icon-box a,
.shadow-dark .item-group-timer > ul > li,
.shadow-dark .cke_inner,
.shadow-dark .cke_wysiwyg_frame,
.shadow-dark .cke_wysiwyg_div,
.shadow-dark .browse-detail-info .browse-detail-timer,
.shadow-dark .discover-process .discover-process-box .discover-process-bar,
.shadow-dark .styled-checkbox + label:before {
	background-color: #131728;
}
.shadow-dark .header-main {
	background-color: #131728;
	border-color: #3f455b;
}
.shadow-dark .nav-item .dropdown-box .dropdown-box-inner,
.shadow-dark .nft-items,
.shadow-dark .nft-items::before {
	background-color: #23293f;
}
.shadow-dark .nft-items {
	box-shadow: none;
}
.shadow-dark .nft-items.nfts-multiple .multiple-layer::before,
.shadow-dark .nft-items.nfts-multiple .multiple-layer::after {
	border: 1px solid rgb(63 69 91 / 60%);
}
.shadow-dark .header-main.fixed-header {
	background-color: #23293f;
	box-shadow: 0px 0px 15px #3f455b;
}
.shadow-dark .item-group-content .items-like > a {
	color: rgb(255 255 255 / 65%) !important;
}
.shadow-dark .discover-box-info-top .theme-title {
	color: #ffffff !important;
}
.shadow-dark .slider-area .slider-information h1,
.shadow-dark .earn-area .earn-main-box .earn-info h2,
.shadow-dark .section-title h2,
.shadow-dark .theme-title,
.shadow-dark .theme-title > a,
.shadow-dark .footer-copyright .copyright-text p,
.shadow-dark .footer-copyright .copyright-text p > a,
.shadow-dark .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link,
.shadow-dark .nav-item .dropdown-box li > a,
.shadow-dark .header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link,
.shadow-dark .switched-styles .hide-button,
.shadow-dark .browse-detail-info h1,
.shadow-dark .discover-box-info-top h2,
.shadow-dark .item-group.item-group-list .item-group-content .item-group-info .theme-title > a,
.shadow-dark .checkout-price-ratting ul > li > h2,
.shadow-dark .nft-input,
.shadow-dark .minting-plan-main .minting-plan-box h2,
.shadow-dark .pricing-box .pricing-header h2,
.shadow-dark .proposals-detail-area .proposals-detail-box h1,
.shadow-dark .order-sucsess-area .order-sucsess-info h1,
.shadow-dark .mintable-guides-info .theme-title,
.shadow-dark .footer-widget .theme-title,
.shadow-dark .promotion-sreact-slider .promotion-sreact-box > a .theme-title,
.shadow-dark .discover-rate-box h2,
.shadow-dark .nft-login-template .fxt-content .fxt-home-link > a {
	color: #ffffff;
}
.shadow-dark .nft-login-template .fxt-content {
	background-color: #111111;
}
.shadow-dark .nft-login-template .fxt-content .styled-checkbox + label:before {
	background-color: rgb(41 171 226 / 20%);
}
.shadow-dark .nft-login-template .fxt-header p,
.shadow-dark .nft-login-template .checkbox label,
.shadow-dark .nft-login-template .checkbox label > a,
.shadow-dark .nft-login-template .fxt-footer p,
.shadow-dark .nft-login-template .switcher-text2,
.shadow-dark .nft-login-template .switcher-text2:hover,
.shadow-dark .nft-login-template .switcher-text,
.shadow-dark .nft-login-template .switcher-text:hover,
.shadow-dark .nft-login-template .fxt-form p,
.shadow-dark .MuiOutlinedInput-input,
.shadow-dark .custom-select {
	color: #b9b9b9;
}
.shadow-dark .nft-login-template .fxt-form .form-control {
	border-color: #333333;
	background-color: #222222;
	color: #ffffff;
}
.shadow-dark .nft-login-template .fxt-btn-fill {
	background-color: #222222;
}
.shadow-dark .label-nft-input {
	color: #888888;
}
.shadow-dark .ui-widget.ui-widget-content {
	border: 1px solid #131728 !important;
	background: #131728;
}
.shadow-dark .bradcrumb-main > .bradcrumb-title h1,
.shadow-dark .bradcrumb-main > .bradcrumb-right > ul > li,
.shadow-dark .bradcrumb-main > .bradcrumb-right > ul > li > a,
.shadow-dark .bradcrumb-main > .bradcrumb-right > ul > li::before,
.shadow-dark .show-result-box h1 {
	color: #ffffff;
}
.shadow-dark .nav-item .dropdown-box li > a.active,
.shadow-dark .nav-item .dropdown-box li > a:hover {
	background-color: #1a1d2c;
}
.shadow-dark .singel-about-box-item .singel-about-box-info .theme-title {
	color: #ffffff !important;
}
.shadow-dark .theme-description,
.shadow-dark .slider-area .slider-information .theme-description,
.shadow-dark .earn-area .earn-main-box .theme-description,
.shadow-dark .section-title .subtitle,
.shadow-dark .styled-checkbox + label,
.shadow-dark .browse-product-right > ul > li .browse-list,
.shadow-dark .item-group-timer > ul > li > span,
.shadow-dark .pagination-box > ul > li > a,
.shadow-dark .browse-product-left > p,
.shadow-dark .min-item-box .min-item-item .theme-title,
.shadow-dark .theme-input-box label,
.shadow-dark .default-switch .switch-label,
.shadow-dark .theme-model-popup-header h3,
.shadow-dark .starting-bid ul li,
.shadow-dark .browse-bid-detail .browse-bid-box h2,
.shadow-dark .browse-time-left ul > li > span,
.shadow-dark .browse-detail-info .browse-detail-timer .browse-time-right h2,
.shadow-dark .browse-detail-info .store-ratting,
.shadow-dark .price-filter-box h2,
.shadow-dark .discover-box-info-top ul li,
.shadow-dark .pricing-box .pricing-content > ul > li,
.shadow-dark .user-policy-box ul li,
.shadow-dark .proposals-sidebar-main > ul > li,
.shadow-dark .switched-styles h4,
.shadow-dark .user-policy-main h2,
.shadow-dark .footer-widget .theme-description,
.shadow-dark .nft-items .nft-highest-bid,
.shadow-dark .nft-items .nft-items-info > h4,
.shadow-dark .nft-items-authore .nft-items-like > span,
.shadow-dark .browse-search-box .browse-search-input,
.shadow-dark .p_list-main .p_list {
	color: #989898;
}
.shadow-dark .browse-tab > ul > li {
	background-color: #23293f;
	border-color: #333;
	color: #989898;
}
.shadow-dark .nft-items-authore .nft-items-like > span:hover {
	background-color: #131728;
	color: #fff;
}
.shadow-dark .header-right-btn > ul > li > .theme-btn,
.shadow-dark .switch-button,
.shadow-dark .owl-nav > button,
.shadow-dark .focus-nft-input::before,
.shadow-dark .tag,
.shadow-dark .pagination-box > ul > li.current {
	background-color: #f38a25;
}
.shadow-dark .header-right-btn > ul > li > .search-btn {
	background-color: rgb(41 171 226 / 15%);
	color: #f38a25 !important;
}
.shadow-dark .header-main.fixed-header .header-right-btn > ul > li > .search-btn {
	background-color: rgb(57 72 104 / 50%);
}
.shadow-dark .header-right-btn > ul > li .login-text,
.shadow-dark .section-title h6,
.shadow-dark .theme-title > a:hover,
.shadow-dark .earn-info h6,
.shadow-dark .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link.active,
.shadow-dark .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link:hover,
.shadow-dark .header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link.active,
.shadow-dark .header-main.fixed-header .header-menu-box .header-navbar-menu .navbar .nav-item .nav-link:hover,
.shadow-dark .mintable-pro-area .earn-main-box .earn-btn .theme-btn,
.shadow-dark .search-close,
.shadow-dark .item-group .item-price,
.shadow-dark .store-ratting > i,
.shadow-dark .item-group-box .item-group-bid h2,
.shadow-dark .styled-checkbox + label > span > i,
.shadow-dark .theme-input-box label > i,
.shadow-dark .wallet-detected-box .wallet-detected-item .wallet-close-btn,
.shadow-dark .modal-content .modal-body .close,
.shadow-dark .user-form .nft-links-btn > a,
.shadow-dark .theme-description > a,
.shadow-dark .styled-checkbox + label > span > a,
.shadow-dark .browse-bid-detail .browse-bid-box h2 span,
.shadow-dark .price-filter-box .price-small,
.shadow-dark .pagination-box > ul > li > a:hover,
.shadow-dark .nft-items .nft-highest-bid > span,
.shadow-dark .nft-items .nft-highest-bid > a,
.shadow-dark .section-title span,
.shadow-dark .earn-info span,
.shadow-dark .faq-box-main .faq-box .theme-title > i {
	color: #f38a25;
}
.shadow-dark .hot-bids-athore > a > img {
	border-color: #3f455b;
	box-shadow: 0px 0px 8px #3f455b;
}
.shadow-dark .most-viewed-items-area {
	background-color: transparent;
	border-color: #3f455b;
}
.shadow-dark .browse-product-right > ul > li .browse-list.active {
	border-color: #3f455b !important;
}
.shadow-dark .upload-images-box .upload-images-item > i {
	color: #f38a25;
}
.shadow-dark .browse-product-right > ul > li .browse-list.active {
	color: #f38a25 !important;
}
.shadow-dark .slider-btn .theme-btn.sl-sale-btn,
.shadow-dark .item-group:hover .item-group-btn .theme-btn,
.shadow-dark .theme-btn,
.shadow-dark .list-select-item > li > span.active,
.shadow-dark .list-select-item > li > span:hover {
	background-color: #f38a25;
	border-color: #f38a25;
}
.shadow-dark .item-group-content .items-like.like-active,
.shadow-dark .view-store-item .view-store-box .item-group-btn .theme-btn,
.shadow-dark .nft-filter .nav > li > a.active,
.shadow-dark .discover-process .discover-process-box .discover-process-bar .progress-bar {
	background-color: #f38a25;
}
.shadow-dark .item-group .store-label,
.shadow-dark .view-store-item .store-label,
.shadow-dark .nft-filter .nav > li > a {
	background-color: #1d2236;
	color: #989898;
}
.shadow-dark .slider-btn .theme-btn {
	background-color: transparent;
	border-color: #444444;
	color: #fff;
}
.shadow-dark .promotion-sreact-slider {
	background-color: #23293f;
	box-shadow: 0 20px 50px 5px #23293f;
	border-color: #3f455b;
}
.shadow-dark .singel-about-box-item .singel-about-box-icon {
	background-color: #23293f;
	box-shadow: 0 20px 50px 5px #111;
}
.shadow-dark .section-title h2[data-watermark]::before {
	-webkit-text-stroke: 1px #3a3a3a;
}
.shadow-dark .item-group,
.shadow-dark .view-store-item,
.shadow-dark .discover-main-box,
.shadow-dark .buy-votes-area .buy-vote-box,
.shadow-dark .mint-item-main-box,
.shadow-dark .mint-item-form .theme-input-box .cke_chrome,
.shadow-dark .wallet-detected-box .wallet-detected-item,
.shadow-dark .checkout-box-main .checkout-item,
.shadow-dark .maintenance-area .maintenance-card,
.shadow-dark .faq-box-main .faq-box,
.shadow-dark .contact-area .contact-box-main,
.shadow-dark .pro-stores-area .pro-store-box,
.shadow-dark .pricing-box,
.shadow-dark .proposals-box-main .proposals-box,
.shadow-dark .proposals-sidebar-box,
.shadow-dark .proposals-detail-area .proposals-detail-box,
.shadow-dark .switched-styles {
	background-color: #23293f;
	box-shadow: 0 20px 50px 5px #23293f;
	-webkit-box-shadow: 0 20px 50px 5px #23293f;
	border-color: #3f455b;
}
.shadow-dark .modal-content .modal-body,
.shadow-dark .MuiOutlinedInput-input,
.shadow-dark .custom-select,
.shadow-dark .MuiInputBase-multiline {
	background-color: #23293f;
	border-color: #3f455b;
}
.shadow-dark .MuiInputBase-multiline .MuiOutlinedInput-input {border: none}
.shadow-dark .browse-detail-info .browse-bid-detail {
	background-color: #23293f;
	border-color: #3f455b;
}
.shadow-dark .browse-tag ul > li {
	background-color: #3e445a;
	color: #131728;
}
.shadow-dark .item-group-btn .theme-btn,
.shadow-dark .nft-items .nft-items-authore,
.shadow-dark .browse-detail-tab .p_list-main,
.shadow-dark .browse-detail-tab .nav-tabs {
	border-color: #3f455b;
}
.shadow-dark .browse-detail-info .browse-detail-timer > div {
	border-color: #444;
}
.shadow-dark .item-group:hover {
	box-shadow: none;
}
.shadow-dark .promisional-secret-area,
.shadow-dark .earn-area.pb-5 {
	border-color: rgb(63 69 91 / 60%);
}
.shadow-dark .em_bar_bg:before,
.shadow-dark .default-switch input:checked + .slider {
	background-color: #f38a25;
}
.shadow-dark .theme-btn::after,
.shadow-dark .theme-btn::before,
.shadow-dark .theme-btn:hover::after,
.shadow-dark .theme-btn:hover::before {
	background-color: #e17f21;
}
.shadow-dark .em_bar_bg {
	background: rgb(41 171 226 / 20%);
}
.shadow-dark .page-background,
.shadow-dark .price-range-slider .range-bar .ui-slider-handle {
	background-color: #23293f;
}
.shadow-dark .top-selling-store .item-group .item-group-content::before {
	background-color: rgb(255 255 255 / 75%);
}
.shadow-dark .footer-copyright {
	border-color: #222;
}
.shadow-dark .footer-copyright {
	background-color: #22293d;
}
.shadow-dark .discover-tab-pane-box .discover-tab-right .discover-tab-buy,
.shadow-dark .min-item-box .min-item-item {
	background-color: #131728;
	color: #999;
}
.shadow-dark .ltr-rtl-demo .ltr-btn.active > a {
	background-color: #313850;
	color: #999;
}
.shadow-dark .min-item-box .min-item-item {
	box-shadow: none;
}
.shadow-dark .mintable-pro-area .earn-main-box,
.shadow-dark .item-group-box > .item-group-btn .theme-btn {
	background-color: #f38a25;
	border-color: #131728;
}
.shadow-dark button.Mui-disabled {
	background-color: #573d24;
}
.shadow-dark .item-group-box > .item-group-btn .theme-btn,
.shadow-dark .nft-filter .nav > li > a.active,
.shadow-dark .item-group-btn .theme-btn {
	color: #ffffff;
}
.shadow-dark .bradcrumb-area {
	background-color: #23293f;
	background-image: none;
}
.shadow-dark .browse-product-filter .filter-box,
.shadow-dark .minting-plan-main .minting-plan-box {
	background-color: #23293f;
	border-color: #3f455b;
	box-shadow: 0 4px 10px 0 #23293f;
}
.shadow-dark .browse-product-filter .filter-box .theme-title,
.shadow-dark .browse-product-top,
.shadow-dark .nav-tabs .nav-link:focus,
.shadow-dark .nav-tabs .nav-link:hover,
.shadow-dark .item-group-box .item-group-bid,
.shadow-dark .discover-rate-tab .discover-bottom,
.shadow-dark .theme-btn.transparent-btn,
.shadow-dark .min-item-box .min-item-item,
.shadow-dark .list-select-item > li > span,
.shadow-dark .mint-item-form .upload-images-box,
.shadow-dark .cke_top,
.shadow-dark .mint-item-form .theme-input-box .cke_chrome,
.shadow-dark .theme-form-title .theme-title::before,
.shadow-dark .fixed-price-tab-box .tab-content,
.shadow-dark .browse-detail-info .browse-bid-detail .browse-refresh,
.shadow-dark .switched-styles .ltr-rtl-demo,
.shadow-dark .checkout-item .checkout-item-info,
.shadow-dark .nft-input-box,
.shadow-dark .theme-file-upload,
.shadow-dark .upload-images-box .upload-images-item > i,
.shadow-dark .faq-box-main .faq-box .theme-title,
.shadow-dark .pricing-box .pricing-plan,
.shadow-dark .proposals-sidebar-box .theme-title,
.shadow-dark .switched-styles h4 {
	border-color: #3f455b;
}
.shadow-dark .theme-file-upload {
	background-color: rgb(19 23 40 / 30%);
}
.shadow-dark .styled-checkbox:checked + label:after {
	background: #f38a25;
	box-shadow: 2px 0 0 #f38a25, 4px 0 0 #f38a25, 4px -2px 0 #f38a25, 4px -4px 0 #f38a25, 4px -6px 0 #f38a25,
		4px -8px 0 #f38a25;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.shadow-dark .browse-product-right > ul > li .browse-list {
	background-color: #23293f !important;
}
.shadow-dark .nft-dao-area .nft-dao-box .nft-dao-img img {
	-webkit-filter: opacity(0.2);
	        filter: opacity(0.2);
}
.shadow-dark .earn-btn .theme-btn.mints-btn {
	background-color: #999;
}
.shadow-dark .theme-form-title .theme-title > span,
.shadow-dark .theme-input-box .theme-input,
.shadow-dark .browse-tag ul > li {
	background-color: #23293f;
	border-color: #3f455b;
	color: #989898;
	transition: background-color 500ms linear;
}
.shadow-dark .browse-tag ul > li:hover {
	background-color: #f38a25;
	color: #fff;
}
.shadow-dark .default-switch .slider {
	background-color: #444;
}
.shadow-dark .wallet-detected-box {
	background-color: rgb(24 24 24 / 92%);
}
.shadow-dark .traditional-gasless-main .traditional-gasless-item {
	background-color: transparent;
	border-color: #3f455b;
}
.shadow-dark .singel-about-box-item .singel-about-box-icon img,
.shadow-dark .nft-dao-area .nft-dao-box .nft-dao-icon img {
	-webkit-filter: invert(1.1);
	        filter: invert(1.1);
}
.shadow-dark .footer-main {
	background-color: #131728 !important;
}
.shadow-dark .footer-icon-box a {
	background-color: #252938;
	color: #fff;
}
.shadow-dark .theme-description.email-text > a {
	color: #989898 !important;
}
.shadow-dark #contact_about option {
	color: #222;
}
.shadow-dark .header-menu-box .header-navbar-menu .navbar .navbar-collapse {
	border-color: #3f455b;
}
.shadow-dark .fixed-header .header-menu-box .header-navbar-menu .navbar .navbar-collapse {
	border-color: inherit !important;
}
.shadow-dark .css-zrdji6 {
	background-color: #23293f;
	box-shadow: var(--st--shadows-0);
}
/* dark mode css ***********/

/* responsive ************/
@media screen and (max-width: 479px) {
	.header-logo a > img {
		max-width: 150px !important;
	}
	.slider-btn .theme-btn {
		width: 100%;
		margin-bottom: 15px;
		margin-right: 0px;
	}
	.singel-about-box .singel-about-box-item {
		text-align: center;
	}
	.singel-about-box-item .singel-about-box-icon {
		float: none;
		margin: 0px auto 20px;
	}
	.singel-about-box-item .singel-about-box-info {
		width: 100%;
		float: none;
	}
	.header-right-btn > ul > li:last-child {
		float: none;
		width: 100%;
	}
	.header-right-btn > ul > li .login-text {
		background-color: #f38a2515;
		text-align: center;
		border-radius: 5px 5px;
	}
	.header-main.fixed-header .header-right-btn > ul > li .login-text {
		display: none;
	}
	.header-main.fixed-header .header-logo {
		padding-bottom: 6px;
	}
	.bradcrumb-area {
		padding: 190px 0px 60px;
	}
	.browse-product-top .row {
		display: block;
	}
	.browse-product-top .row > div {
		display: block;
		width: 100%;
	}
	.browse-product-left > p {
		text-align: center;
	}
	.browse-product-right > ul {
		float: none;
		display: flex;
		justify-content: center;
		margin-top: 15px;
	}
	.view-store-box {
		display: block;
		width: 100%;
	}
	.view-store-item {
		padding: 15px 15px;
	}
	.view-store-box .view-store-images {
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}
	.view-store-box .view-store-info {
		width: 100%;
		padding: 0px;
	}
	.view-store-box .view-store-info .item-group-box {
		margin-bottom: 16px;
	}
	.header-right-btn > ul > li .theme-btn.search-btn {
		display: block;
	}
	.header-right-btn > ul > li .theme-btn {
		display: none;
	}
	.nft-filter .nav > li > a {
		font-size: 11px;
		margin: 0px;
	}
	.theme-box-center .row {
		display: block;
	}
	.theme-box-center .row > div {
		width: 100%;
		display: block;
	}
	.theme-box-center .row > div:first-child {
		margin-bottom: 15px;
	}
	.buy-vote-form .nft-input-box {
		width: calc(100% - 30px);
	}
	.earn-area .earn-main-box .earn-images img {
		max-width: 240px !important;
	}
	.user-logo img {
		max-width: 240px !important;
	}
	.minting-plan-main .minting-plan-box {
		width: calc(100% - 16px);
	}
	.user-screen-item .nft-links-btn .theme-description > a {
		display: block;
	}
	.earn-area .earn-main-box {
		text-align: center;
	}
	.earn-area .earn-main-box > .earn-info .em_bar {
		margin: 0px auto 25px;
	}
	.earn-area .earn-main-box .earn-info ul {
		text-align: left;
	}
	.theme-title {
		font-size: 16px;
	}
	.checkout-item .checkout-item-media {
		float: none;
		margin: 0px auto;
	}
	.checkout-item .checkout-item-info {
		width: 100%;
		margin: 0px;
		float: none;
		padding: 0px;
		border: 0px;
	}
	.checkout-item .checkout-item-info .checkout-info-box {
		width: 100%;
		float: none;
		margin-top: 15px;
	}
	.checkout-box-main .checkout-item .checkout-price-ratting {
		position: static;
		margin-top: 15px;
		text-align: center;
	}
	.checkout-price-ratting ul > li {
		float: none;
		display: inline-block;
	}
	.browse-vote-btn .theme-btn {
		width: 100%;
	}
	.browse-thumblain > ul > li {
		width: 50px;
	}
	.browse-detail-info .browse-detail-timer {
		display: block;
		width: calc(100% - 30px);
		margin: 0px auto;
	}
	.browse-bid-footer .theme-description {
		margin-top: 12px;
	}
	.browse-detail-info .browse-detail-timer > div {
		display: block;
		width: 100%;
		border: 0px;
		padding: 0px;
	}
	.browse-detail-info .browse-detail-timer .browse-time-right {
		padding: 0px;
		text-align: center;
		margin-top: 15px;
	}
	.browse-thumblain > ul {
		text-align: left !important;
	}
}
@media screen and (max-width: 575px) and (min-width: 480px) {
	.minting-plan-main .minting-plan-box {
		width: calc(50% - 16px);
	}
	.checkout-item .checkout-item-info .checkout-info-box {
		width: 33.33% !important;
		float: left !important;
	}
	.checkout-item .checkout-item-info .checkout-info-box .theme-title {
		font-size: 15px;
	}
}
@media screen and (max-width: 767px) and (min-width: 575px) {
	.minting-plan-main .minting-plan-box {
		width: calc(33.33% - 16px);
	}
}
@media screen and (max-width: 767px) and (min-width: 480px) {
	.header-main .header-logo img {
		max-width: 150px !important;
	}
	.header-right-btn > ul > li .login-text {
		position: absolute;
		top: 0px;
		right: 15px;
	}
	/* .header-main .header-logo {
		padding: 0px 0px 8px !important;
	} */
	.header-right-btn > ul > li:first-child {
		right: 70px !important;
	}
	/* .header-menu-box .header-navbar-menu .navbar .navbar-toggler {
		right: 136px !important;
	} */
	.header-right-btn > ul > li > .theme-btn {
		background-color: #f38a2515;
		color: #f38a25;
		font-weight: 700;
	}
	.top-selling-store .top-selling-width {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.checkout-item .checkout-item-media {
		float: none;
		margin: 0px auto;
	}
	.checkout-item .checkout-item-info {
		width: 100%;
		margin: 0px;
		float: none;
		padding: 0px;
		border: 0px;
	}
	.checkout-item .checkout-item-info .checkout-info-box {
		margin-top: 15px;
	}
	.checkout-item .checkout-item-info .checkout-info-box .theme-title {
		font-size: 15px;
	}
	.checkout-box-main .checkout-item .checkout-price-ratting {
		position: static;
		margin-top: 15px;
		text-align: center;
	}
	.checkout-price-ratting ul > li {
		float: none;
		display: block;
	}
}
@media screen and (max-width: 767px) and (min-width: 575px) {
	.view-store-box .view-store-images {
		width: 180px;
	}
	.view-store-box .view-store-info {
		width: calc(100% - 180px);
	}
	.view-store-box .view-store-info .item-group-box {
		margin-bottom: 15px;
	}
	.buy-vote-form .nft-input-box {
		width: calc(100% - 30px);
	}
}
@media screen and (max-width: 575px) {
	.header-menu-box .header-right-btn {
		float: none;
		margin: 0px;
	}
	.earn-area .earn-main-box .earn-info h2 {
		font-size: 26px;
	}
	.section-title h2 {
		font-size: 30px;
	}
	.mintable-pro-area .earn-main-box {
		padding: 30px 15px;
	}
	.bradcrumb-main > div {
		display: block;
		width: 100%;
		text-align: center;
	}
	.bradcrumb-main > .bradcrumb-right > ul {
		float: none;
		display: block;
		justify-content: center;
		margin-top: 15px;
	}
	.bradcrumb-main > .bradcrumb-right > ul > li {
		display: inline-block;
		float: none;
	}
	.view-store-box {
		display: block;
		width: 100%;
	}
	.view-store-item {
		padding: 15px 15px;
	}
	.view-store-box .view-store-images {
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}
	.view-store-box .view-store-info {
		width: 100%;
		padding: 0px;
	}
	.view-store-box .view-store-info .item-group-box {
		margin-bottom: 16px;
	}
	.discover-main-box > .row {
		display: block;
		width: calc(100% - -15px);
	}
	.discover-main-box > .row > div {
		display: block;
		width: 100% !important;
	}
	.discover-area .discover-box-images {
		margin-bottom: 20px;
	}
	.discover-main-box {
		padding: 30px 5px 30px 20px;
	}
	.discover-box-info-top h2 {
		font-size: 26px;
	}
	.min-item-box .min-item-item {
		width: calc(100% - 30px);
	}
	.buy-vote-box .buy-vote-btn .theme-btn {
		width: 100%;
		padding: 13px 15px;
	}
	.user-logo img {
		max-width: 320px;
	}
	.contact-area .contact-box-main {
		padding: 20px 15px;
	}
	.header-right-btn > ul > li > .theme-btn {
		display: none;
	}
	.header-right-btn > ul > li > .theme-btn.search-btn {
		display: block;
	}
	.align-right-mobile {
		text-align: right;
	}
	.nav-tabs .nav-link {
		padding: 0.5rem;
	}
}
@media screen and (max-width: 767px) {
	body .container {
		max-width: 100%;
	}
	.header-menu-box .header-navbar-menu .navbar .navbar-toggler {
		/* position: absolute;
		top: 0px;
		right: 15px; */
		height: 45px;
		width: 45px;
		background-color: #f38a25;
		color: #fff;
		border-radius: 5px 5px;
		font-size: 20px;
		padding: 0px;
	}
	.header-main .row {
		position: relative;
	}
	.header-main .row > div,
	.header-menu-box .header-navbar-menu .navbar {
		position: static;
	}
	.header-main .header-menu-box,
	.header-menu-box .header-navbar-menu {
		float: none;
	}
	.header-main .header-logo {
		/* padding: 0px 0px 20px; */
	}
	.header-right-btn, .user-panel-header-top-right {
		display: none;
	}
	.header-right-btn > ul > li:first-child {
		position: absolute;
		top: 0px;
		right: 15px;
	}
	.header-menu-box .header-navbar-menu .navbar .navbar-collapse {
		position: absolute;
		top: 53px;
		background-color: #e1e1e1;
		width: 100%;
		left: 0px;
		right: 0px;
		padding: 25px 15px;
		border-top: 1px solid #eee;
	}
	.header-right-btn > ul > li > .theme-btn {
		display: none;
	}
	.header-right-btn > ul > li > .theme-btn.search-btn {
		display: block;
	}
	.header-menu-box .header-navbar-menu .navbar .nav-item {
		margin: 0px;
	}
	.header-menu-box .header-navbar-menu .navbar .navbar-collapse .navbar-nav {
		max-height: calc(100vh - 100px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.nav-item .dropdown-box li > a:hover,
	.nav-item .dropdown-box li > a.active {
		background-color: #f38a25;
		color: #fff;
	}
	.navbar-nav .nav-item .dropdown-box {
		position: static;
		width: calc(100% - -35px);
		margin: 0px -20px;
		padding: 0px 20px;
	}
	.navbar-nav .nav-item .dropdown-box .dropdown-box-inner {
		background-color: #f5f5f5;
		box-shadow: none;
	}
	.slider-area .slide-animate-img {
		display: none;
	}
	.slider-area .slider-inner {
		text-align: center;
	}
	.slider-area .slider-information .em_bar {
		margin: 0px auto 25px;
	}
	.earn-area .earn-main-box {
		display: flex;
		flex-wrap: wrap;
	}
	.earn-area .earn-main-box > .earn-info {
		order: 2;
	}
	.earn-area .earn-main-box .earn-images {
		order: 1;
	}
	.earn-area .earn-main-box > div {
		width: 100%;
		display: block;
	}
	.earn-area .earn-main-box .earn-images {
		margin-bottom: 20px;
		text-align: center;
	}
	.earn-area .earn-main-box .earn-images img {
		max-width: 380px;
	}
	.slider-area .slider-inner .row {
		display: block;
		height: auto;
		width: 100%;
		margin: 0px auto;
		/* padding-top: 100px; */
	}
	.slider-area .slider-inner .row > div {
		display: block;
		width: 100%;
	}
	.slider-area .slider-information h1 {
		font-size: 38px;
	}
	.slider-area {
		margin-bottom: 50px;
	}
	.discover-tab-pane-box {
		display: block;
		width: 100%;
	}
	.discover-tab-pane-box > div {
		width: 100% !important;
		display: block;
	}
	.discover-tab-pane-box .discover-tab-left {
		margin-bottom: 20px;
	}
	.user-screen-box > .user-screen-item.screen-bg-image {
		padding: 120px 0px;
	}
	.user-screen-box {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		height: auto;
	}
	.user-screen-box > .user-screen-item {
		display: block;
		width: 100% !important;
	}
	.user-screen-box > .user-screen-item.screen-bg-image {
		order: 1;
	}
	.user-screen-box > .user-screen-item.screen-form {
		order: 2;
		padding: 25px 25px 130px 25px;
	}
	.pricing-box .pricing-content {
		height: auto;
	}
	.browse-detail-info h1 {
		font-size: 22px;
		margin-top: 12px;
	}
	.browse-thumblain > ul {
		text-align: center;
	}
	.browse-thumblain > ul > li {
		width: 30%;
		float: none;
		display: inline-block;
		margin: 1.5%;
	}
	.mintable-guides-box {
		margin: 10px 0
	}
	.page-paddings {
		padding: 100px 0;
	}
	.page-user-profile .page-paddings {
		padding-top: 140px;
	}
}
@media screen and (max-width: 991px) and (min-width: 768px) {
	.header-menu-box .header-navbar-menu .navbar .navbar-toggler {
		height: 45px;
		width: 45px;
		background-color: #f38a25;
		color: #fff;
		position: relative;
		left: 5px;
	}
	.header-main .row {
		position: relative;
	}
	.header-menu-box .header-navbar-menu .navbar,
	.header-main .row > div {
		position: static;
	}
	/* .header-menu-box .header-navbar-menu .navbar .navbar-toggler {
		position: absolute;
		top: 0px;
		right: 322px;
		left: inherit;
	} */
	.header-menu-box .header-navbar-menu .navbar .navbar-collapse {
		position: absolute;
		top: 100%;
		left: 0px;
		right: 0px;
		background-color: #2b3045;
		border-top: 1px solid #eee;
		margin: 15px 0px 0px;
		padding: 25px 15px;
		/* box-shadow: 0px 8px 15px #eeeeee; */
	}
	.slider-area .slide-animate-img {
		display: none;
	}
	.slider-area .slider-information h1 {
		font-size: 40px;
	}
	.slider-area .slider-inner .row {
		height: auto;
		padding: 30px 0px;
	}
	.slider-btn .theme-btn {
		width: 100%;
		text-align: center;
		margin-bottom: 12px;
	}
	.earn-area .earn-main-box .earn-info h2 {
		font-size: 28px;
	}
	.section-title h2 {
		font-size: 34px;
	}
	body .container {
		max-width: 900px;
	}
	.view-store-box .view-store-info .item-group-box {
		margin-bottom: 15px;
	}
	.view-store-box .view-store-images {
		width: 180px;
	}
	.view-store-box .view-store-info {
		width: calc(100% - 180px);
	}
	.discover-tab-pane-box .discover-tab-left {
		width: 65%;
	}
	.discover-tab-pane-box .discover-tab-right {
		width: 35%;
	}
	.user-screen-box > .user-screen-item.screen-form {
		width: 60%;
	}
	.user-screen-box > .user-screen-item.screen-bg-image {
		width: 40%;
	}
	.user-logo img {
		max-width: 180px;
	}
	.minting-plan-main .minting-plan-box {
		width: calc(33.33% - 16px);
	}
	.checkout-item .checkout-item-media {
		float: none;
		margin: 0px auto;
	}
	.checkout-box-main .checkout-item {
		text-align: center;
	}
	.checkout-box-main .checkout-item .checkout-price-ratting {
		position: static;
		margin-top: 15px;
	}
	.checkout-price-ratting ul > li {
		float: none;
		display: block;
	}
	.checkout-item .checkout-item-info {
		width: 100%;
		float: none;
		margin: 0px;
		padding: 0px;
		border: 0px;
	}
	.checkout-item .checkout-item-info .checkout-info-box {
		width: 50%;
		margin-top: 15px;
	}
	.checkout-item .checkout-item-info .checkout-info-box .theme-title {
		font-size: 15px;
	}
	.browse-thumblain > ul > li {
		width: calc(33% - 16px);
	}
	.browse-vote-btn .theme-btn {
		width: 100%;
	}
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
	.slider-area .slider-information h1 {
		font-size: 40px;
	}
	.slider-area .slider-inner .row {
		height: auto;
		padding: 30px 0px;
	}
	.slider-btn .theme-btn {
		width: 100%;
		text-align: center;
		margin-bottom: 12px;
	}
	.earn-area .earn-main-box .earn-info h2 {
		font-size: 28px;
	}
	.section-title h2 {
		font-size: 34px;
	}
	.header-menu-box .header-navbar-menu .navbar .nav-item {
		margin-right: 12px;
	}
	.slider-images img {
		max-width: 380px;
	}
	.header-menu-box .header-navbar-menu .navbar .nav-item .nav-link {
		font-size: 14px;
		padding: 12px 0px;
	}
	.user-screen-box > .user-screen-item.screen-form {
		width: 40%;
	}
	.user-screen-box > .user-screen-item.screen-bg-image {
		width: 60%;
	}
	.user-logo img {
		max-width: 280px;
	}
	.minting-plan-main .minting-plan-box {
		width: calc(33.33% - 16px);
	}
	.pricing-box .pricing-content {
		height: 500px;
	}
	.checkout-item .checkout-item-info .checkout-info-box .theme-title {
		font-size: 15px;
	}
	.checkout-box-main .checkout-item .checkout-price-ratting {
		bottom: 20px;
	}
	.browse-thumblain > ul > li {
		width: 60px;
	}
}
@media screen and (max-width: 1600px) and (min-width: 1200px) {
	.slider-area .slider-information h1 {
		font-size: 42px;
	}
	.slider-images img {
		max-width: 400px;
	}
	.user-screen-box > .user-screen-item.screen-form {
		width: 30%;
	}
	.user-screen-box > .user-screen-item.screen-bg-image {
		width: 70%;
	}
	.user-logo img {
		max-width: 280px;
	}
}
@media screen and (min-width: 1200px) {	
	.user-setting-box-area > .user-setting-box-top {
		display: table;
		width: calc(100% - -30px);
	}
	.user-setting-box-area > .user-setting-box-top > div {
		display: table-cell;
		vertical-align: middle;
	}
	.user-setting-box-area > .user-setting-box-top > .user-setting-left {
		width: 25%;
	}
	.user-setting-box-area > .user-setting-box-top > .user-setting-right {
		width: 75%;
	}
}
.shadow-dark .MuiModal-root {
	background: rgba(0, 0, 0, 0.75);
}
.shadow-dark .MuiDialogContent-root {
	background: #23293f;
	border: 1px solid #3f455b;
	color: #989898;
}


/* user setting css */
.user-panel-main-box .user-panel-main {
    padding: 30px 30px;
    padding-top: 86px;
}
.user-panel-breadcrumb {
    margin-bottom: 30px;
    padding: 20px 30px;
    border-bottom: 1px solid #3f455b;
}
.user-panel-breadcrumb .row {
    display: table;
    width: calc(100% - -30px);
}
.user-panel-breadcrumb .row > div {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
}
.user-panel-breadcrumb-left h1 {
    margin-bottom: 5px;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;
    position: relative;
    letter-spacing: 2px;
}
.user-link a {
    font-size: 16px;
}
.user-panel-breadcrumb-right ul {
    margin: 0px;
    float: right;
}
.user-top-seller-box, 
.user-setting-box > a {
    display: inline-block;
    background-color: #fff;
    padding: 15px 15px;
    border: 1px solid #eaeff5;
    box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
    -webkit-box-shadow: 0 4px 10px 0 rgb(41 128 185 / 7%);
    border-radius: 6px;
    text-align: center;
    color: #888;
}
.user-setting-box > a {
    padding: 17px 15px;
}
.user-top-seller-box h4, 
.user-setting-box > a > h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #888;
    margin: 8px 0px 0px;
}
.user-panel-breadcrumb-right ul > li {
    float: left;
    margin-right: 15px;
}
.user-panel-breadcrumb-right ul > li:last-child {
    margin-right: 0px;
}
.user-setting-box > a > i {
    font-size: 22px;
    margin-bottom: 4px;
    color: #394868;
}
.user-setting-box-area .theme-input-box {
    margin-top: 15px;
}
.setting-change-profile .change-profile-icon {
    height: 120px;
    width: 120px;
    background-color: #f2f4ff;
    border-radius: 100%;
    font-size: 58px;
    font-weight: 500;
    text-align: center;
    line-height: 120px;
    color: #f38a25;
    margin: 0px auto;
    position: relative;
    cursor: pointer;
}
.setting-change-profile .change-profile-icon > i {
    height: 38px;
    width: 38px;
    border-radius: 100%;
    font-size: 16px;
    background-color: #fff;
    color: #0c5adb;
    line-height: 35px;
    position: absolute;
    bottom: -6px;
    right: 8px;
    border: 2px solid #0c5adb;
}
.setting-change-profile .user-title {
    font-size: 16px;
    margin-top: 16px;
    color: #666;
    font-weight: 600;
}

.user-no-record-found {
    padding: 140px 0px;
    text-align: center;
}
.user-no-record-found.order-no-record {
    padding: 75px 0px;
}
.user-no-record-found .user-no-record-icon {
    height: 100px;
    width: 100px;
    background-color: #f2f4ff;
    color: #f38a25;
    font-size: 36px;
    border-radius: 100%;
    line-height: 100px;
    text-align: center;
    margin: 0px auto 20px;
}
.user-no-record-found .user-no-record-img {
    margin-bottom: 30px;
}
.user-no-record-found .user-title {
    margin-bottom: 6px;
}
.user-no-record-found .theme-description {
    max-width: 600px;
    margin: 0px auto 20px;
    word-break: break-word;
}
.user-store-item {display: flex; align-items: center;}
.user-store-item > div {
    vertical-align: middle;
	flex-grow: 1;
	max-width: 500px;
}
.user-store-item .user-store-media {
    width: 260px;
    padding-right: 20px;
	flex-grow: 0;
}
/* user setting css */
.anchor {cursor: pointer;}
.add-new-block {
	cursor: pointer;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 30px !important;
}
.add-new-block svg {
	width: 60px;
	height: 60px;
}

.error-pill {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 15px;
    background: #990000;
    border-radius: 10px;
    color: #FFF;
}

.archived-message {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #990000;
    max-width: 500px;
    margin: 0 auto;
}

.shadow-dark .MuiPaginationItem-outlined {
	background-color: #eeeeee75;
}
.shadow-dark .MuiPaginationItem-outlined.Mui-selected {
	background-color: #f38a25;
	color: #FFF;
	border-color: #FFF;
}

.theme-table table {
    width: 100%;
    border: 1px solid #3f455b;
}
.theme-table table thead {
    background-color: #3f455b;
}
.theme-table table thead > tr > th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 12px 20px;
	color: #FFF;
    text-align: left;
    letter-spacing: 1px;
}
.theme-table table tbody > tr:nth-child(2n) {
    background-color: rgb(12 90 219 / 2%);
}
.theme-table table tbody > tr > td {
    font-size: 14px;
    text-align: left;
    padding: 12px 20px;
    letter-spacing: 1px;
	text-transform: capitalize;
}
.theme-table .alert {
    padding: 5px 15px;
    margin: 0px;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
}

.MuiPagination-ul {justify-content: center;}

.embedded-view {padding-top: 15px !important;}

.nav-tabs .nav-link {
	background: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	background: rgb(12 90 219 / 20%);
	color: #000;
	font-weight: 700;
	border-color: rgb(12 90 219 / 20%);
}

.nav-tabs {
	border-color: rgb(12 90 219 / 20%);
}

/*** onfindo styles override ***/
.onfido-sdk-ui-Modal-inner {
	width: 100% !important;
}

.ods-field {
	min-width: auto !important;
}

.nolegend legend {
	display: none !important;
}
.fxt-logo .login-logo {
    margin: 0px auto;
}
.nft-login-template {
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  position: relative;
  z-index: 1;
}
.nft-login-template::before {
    content: "";
    background-image: url(/static/media/login-background.aefb7208.jpg);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}
.nft-login-template::after {
    content: "";
    background-color: #222;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.6;
}
.nft-login-template .fxt-checkbox-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.nft-login-template .fxt-bg-color {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  padding: 0;
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.07);
}
.nft-login-template .fxt-bg-img {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.nft-login-template .fxt-content {
    padding: 65px 90px 45px;
    width: 100%;
    background-color: #fff;
    position: relative;
    z-index: 1;
}
.nft-login-template .fxt-content .fxt-home-link {
    position: absolute;
    top: 15px;
    left: 15px;
}
.nft-login-template .fxt-content .fxt-home-link > a {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 800;
    color: #222;
}
@media only screen and (max-width: 767px) {
  .nft-login-template .fxt-content {
    padding: 65px 30px 45px;
  }
}
.nft-login-template .fxt-header {
    text-align: center;
    margin-bottom: 28px;
}
.nft-login-template .fxt-header .fxt-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 7px;
  max-width: 150px;
}
.nft-login-template .fxt-header h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 10px;
}
.nft-login-template .fxt-header p {
  color: #999898;
}
.nft-login-template .fxt-form h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
.nft-login-template .fxt-form p {
  font-size: 18px;
  color: #111111;
  text-align: center;
}
.nft-login-template .fxt-form form .input-label {
  color: #979696;
}
.nft-login-template .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.nft-login-template .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #a1a1a1;
}
.nft-login-template .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.nft-login-template .fxt-form .form-control {
  min-height: 50px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  color: #111;
}
.nft-login-template .fxt-form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.nft-login-template .fxt-btn-fill {
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #111;
  padding: 10px 36px;
  margin-bottom: 0px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.nft-login-template .fxt-btn-fill:hover {
  background-color: #333;
  border-color: #333;
}
.nft-login-template .fxt-btn-fill:focus {
  outline: none;
}
.nft-login-template .switcher-text {
    color: #222222;
    font-weight: 800;
    font-size: 15px;
    margin-bottom: 0px;
    display: block;
    transition: all 0.3s ease-in-out;
}
.nft-login-template .switcher-text:hover {
  color: #000000;
}
.nft-login-template .switcher-text2 {
    color: #222;
    font-weight: 800;
    font-size: 15px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
.nft-login-template .switcher-text2.inline-text {
  margin-left: 3px;
}
.nft-login-template .switcher-text2:hover {
  color: #000000;
}
.nft-login-template .fxt-style-line {
  overflow: hidden;
  text-align: center;
}
.nft-login-template .fxt-style-line h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 20px;
  color: #a4a4a4;
  display: inline-block;
  position: relative;
  padding: 0 25px;
  z-index: 1;
}
.nft-login-template .fxt-style-line h3:before {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.nft-login-template .fxt-style-line h3:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #ebebeb;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}
.nft-login-template ul.fxt-socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 20px;
}
.nft-login-template ul.fxt-socials li {
  max-width: 100%;
  flex: 0 0 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .nft-login-template ul.fxt-socials li {
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 350px) {
  .nft-login-template ul.fxt-socials li {
    flex: 0 0 100%;
  }
}
.nft-login-template ul.fxt-socials li a {
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 45px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.nft-login-template ul.fxt-socials li a i {
  border-radius: 2px 0 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}
.nft-login-template ul.fxt-socials li a span {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a {
  background-color: #3b5998;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a i {
  background-color: #4867aa;
}
.nft-login-template ul.fxt-socials li.fxt-facebook a:hover {
  background-color: #5676bb;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a {
  background-color: #00acee;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a i {
  background-color: #33ccff;
}
.nft-login-template ul.fxt-socials li.fxt-twitter a:hover {
  background-color: #3dc5f3;
}
.nft-login-template ul.fxt-socials li.fxt-google a {
  background-color: #CC3333;
}
.nft-login-template ul.fxt-socials li.fxt-google a i {
  background-color: #db4437;
}
.nft-login-template ul.fxt-socials li.fxt-google a:hover {
  background-color: #e75042;
}
.nft-login-template .checkbox {
  padding-left: 5px;
  margin-right: 30px;
  margin-bottom: 5px;
}
.nft-login-template .checkbox label {
  padding-left: 20px;
  color: #a4a4a4;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.nft-login-template .checkbox label > a {
    font-weight: 600;
    color: #222;
}
.nft-login-template .checkbox label:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nft-login-template .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.nft-login-template .checkbox input[type="checkbox"] {
  display: none;
}
.nft-login-template .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 3px;
}
.nft-login-template .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #111;
  border-color: #111;
}
.nft-login-template .fxt-footer {
  text-align: center;
}
.nft-login-template .fxt-footer p {
  color: #999898;
}
.nft-login-template p.Mui-error {text-align: left; font-size: 14px; color: #d32f2f !important;}
.timeline_ps_timeline_sec__141ei {
  position: relative;
  text-align: center;
  margin: 25px 0;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW {
  position: relative;
  padding: 180px 0;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol:before {
  background: #f38a25;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol:after {
  background: #f38a25;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv {
  margin: 0;
  padding: 0;
  border-top: 2px solid #f38a25;
  list-style: none;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li {
  float: left;
  width: 25%;
  padding-top: 30px;
  position: relative;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  background: #fff;
  border: 4px solid #f38a25;
  border-radius: 50%;
  box-shadow: 0 0 0 0px #fff;
  text-align: center;
  line-height: 1.75em;
  color: #f38a25;
  font-size: 1.5em;
  font-style: normal;
  position: absolute;
  top: -26px;
  left: 50%;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_top__2oO03:before {
  content: "";
  color: #f38a25;
  width: 2px;
  height: 50px;
  background: #f38a25;
  position: absolute;
  top: -50px;
  left: 50%;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_top__2oO03:after {
  content: "";
  color: #f38a25;
  width: 8px;
  height: 8px;
  background: #f38a25;
  position: absolute;
  bottom: 90px;
  left: 44%;
  border-radius: 100%;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_bot__2ylan:before {
  content: "";
  color: #f38a25;
  width: 2px;
  height: 50px;
  background: #f38a25;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_bot__2ylan:after {
  content: "";
  color: #f38a25;
  width: 8px;
  height: 8px;
  background: #f38a25;
  position: absolute;
  top: 90px;
  left: 44%;
  border-radius: 100%;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_top__2ncfs {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
  text-align: center;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_top__2ncfs img {
  display: table;
  margin: 0 auto;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_bot__3KBcj {
  position: absolute;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_bot__3KBcj img {
  display: table;
  margin: 0 auto;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li p {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_top__2N25O,
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_bot__vV2Xv {
  width: 100%;
  font-size: 14px;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_top__2N25O strong,
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_bot__vV2Xv strong {
  font-size: 18px
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_top__2N25O {
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
}
.timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_bot__vV2Xv {
  position: absolute;
  margin-top: 35px;
}

@media screen and (max-width: 767px) {
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol:before {
    background: #f38a25;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 130px !important;
    left: 21px !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol:after {
    background: #f38a25;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: inherit !important;
    left: 21px;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv {
    margin: 0 !important;
    border-left: 2px solid #f38a25;
    padding-left: 0 !important;
    padding-top: 100px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li {
    height: auto;
    min-height: 150px;
    float: none !important;
    width: inherit !important;
    padding: 0
  }
  .timeline_img_handler_bot__3KBcj, .timeline_ps_top__2N25O, .timeline_ps_bot__vV2Xv, .timeline_img_handler_top__2ncfs
  {position: static !important}
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li:nth_child(2) .timeline_img_handler_bot__3KBcj img {
    width: 70px;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li:last_child {
    margin: 0;
    bottom: 0 !important;
    height: 120px;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li:last_child .timeline_img_handler_bot__3KBcj {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li:last_child .timeline_img_handler_bot__3KBcj img {
    width: 100%;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li:last_child .timeline_ps_top__2N25O {
    margin-bottom: 0 !important;
    top: 20px;
    width: 50% !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span {
    left: 0 !important;
    top: 0 !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_top__2oO03:before {
    content: none !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_top__2oO03:after {
    content: none !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_bot__2ylan:before {
    content: none !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li span.timeline_ps_sp_bot__2ylan:after {
    content: none !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_top__2ncfs {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
    text-align: left !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_top__2ncfs img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_item_container__2ospB {
    margin-left: 50px;
  }

  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_img_handler_bot__3KBcj {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
    text-align: left;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_top__2N25O {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW ol.timeline_ps_timeline__27olv li .timeline_ps_bot__vV2Xv {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
  }
  .timeline_ps_timeline_sec__141ei .timeline_container__1u7QW {
    padding: 130px 0 !important;
  }
}
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.burntToken {
  text-decoration: line-through;
  background: url(/static/media/burnt-bg.be6d201b.svg) #fff5f5;
}

hr {height: 1px; background-color: #EEE; border: none;}
