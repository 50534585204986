.ps_timeline_sec {
  position: relative;
  text-align: center;
  margin: 25px 0;
}
.ps_timeline_sec .container {
  position: relative;
  padding: 180px 0;
}
.ps_timeline_sec .container ol:before {
  background: #f38a25;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-5px);
}
.ps_timeline_sec .container ol:after {
  background: #f38a25;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-5px);
}
.ps_timeline_sec .container ol.ps_timeline {
  margin: 0;
  padding: 0;
  border-top: 2px solid #f38a25;
  list-style: none;
}
.ps_timeline_sec .container ol.ps_timeline li {
  float: left;
  width: 25%;
  padding-top: 30px;
  position: relative;
}
.ps_timeline_sec .container ol.ps_timeline li span {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  background: #fff;
  border: 4px solid #f38a25;
  border-radius: 50%;
  box-shadow: 0 0 0 0px #fff;
  text-align: center;
  line-height: 1.75em;
  color: #f38a25;
  font-size: 1.5em;
  font-style: normal;
  position: absolute;
  top: -26px;
  left: 50%;
}
.ps_timeline_sec .container ol.ps_timeline li span.ps_sp_top:before {
  content: "";
  color: #f38a25;
  width: 2px;
  height: 50px;
  background: #f38a25;
  position: absolute;
  top: -50px;
  left: 50%;
}
.ps_timeline_sec .container ol.ps_timeline li span.ps_sp_top:after {
  content: "";
  color: #f38a25;
  width: 8px;
  height: 8px;
  background: #f38a25;
  position: absolute;
  bottom: 90px;
  left: 44%;
  border-radius: 100%;
}
.ps_timeline_sec .container ol.ps_timeline li span.ps_sp_bot:before {
  content: "";
  color: #f38a25;
  width: 2px;
  height: 50px;
  background: #f38a25;
  position: absolute;
  bottom: -50px;
  left: 50%;
}
.ps_timeline_sec .container ol.ps_timeline li span.ps_sp_bot:after {
  content: "";
  color: #f38a25;
  width: 8px;
  height: 8px;
  background: #f38a25;
  position: absolute;
  top: 90px;
  left: 44%;
  border-radius: 100%;
}
.ps_timeline_sec .container ol.ps_timeline li .img_handler_top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
  text-align: center;
}
.ps_timeline_sec .container ol.ps_timeline li .img_handler_top img {
  display: table;
  margin: 0 auto;
}
.ps_timeline_sec .container ol.ps_timeline li .img_handler_bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}
.ps_timeline_sec .container ol.ps_timeline li .img_handler_bot img {
  display: table;
  margin: 0 auto;
}
.ps_timeline_sec .container ol.ps_timeline li p {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.ps_timeline_sec .container ol.ps_timeline li .ps_top,
.ps_timeline_sec .container ol.ps_timeline li .ps_bot {
  width: 100%;
  font-size: 14px;
}
.ps_timeline_sec .container ol.ps_timeline li .ps_top strong,
.ps_timeline_sec .container ol.ps_timeline li .ps_bot strong {
  font-size: 18px
}
.ps_timeline_sec .container ol.ps_timeline li .ps_top {
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
}
.ps_timeline_sec .container ol.ps_timeline li .ps_bot {
  position: absolute;
  margin-top: 35px;
}

@media screen and (max-width: 767px) {
  .ps_timeline_sec .container ol:before {
    background: #f38a25;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 130px !important;
    left: 21px !important;
  }
  .ps_timeline_sec .container ol:after {
    background: #f38a25;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: inherit !important;
    left: 21px;
  }
  .ps_timeline_sec .container ol.ps_timeline {
    margin: 0 !important;
    border-left: 2px solid #f38a25;
    padding-left: 0 !important;
    padding-top: 100px !important;
    border-top: 0 !important;
    margin-left: 25px !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li {
    height: auto;
    min-height: 150px;
    float: none !important;
    width: inherit !important;
    padding: 0
  }
  .img_handler_bot, .ps_top, .ps_bot, .img_handler_top
  {position: static !important}
  .ps_timeline_sec .container ol.ps_timeline li:nth_child(2) .img_handler_bot img {
    width: 70px;
  }
  .ps_timeline_sec .container ol.ps_timeline li:last_child {
    margin: 0;
    bottom: 0 !important;
    height: 120px;
  }
  .ps_timeline_sec .container ol.ps_timeline li:last_child .img_handler_bot {
    bottom: 40px !important;
    width: 40% !important;
    margin-left: 25px !important;
    margin-top: 0 !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li:last_child .img_handler_bot img {
    width: 100%;
  }
  .ps_timeline_sec .container ol.ps_timeline li:last_child .ps_top {
    margin-bottom: 0 !important;
    top: 20px;
    width: 50% !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li span {
    left: 0 !important;
    top: 0 !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li span.ps_sp_top:before {
    content: none !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li span.ps_sp_top:after {
    content: none !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li span.ps_sp_bot:before {
    content: none !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li span.ps_sp_bot:after {
    content: none !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li .img_handler_top {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
    text-align: left !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li .img_handler_top img {
    margin: 0 auto !important;
    width: 80% !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li .item_container {
    margin-left: 50px;
  }

  .ps_timeline_sec .container ol.ps_timeline li .img_handler_bot {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
    text-align: left;
  }
  .ps_timeline_sec .container ol.ps_timeline li p {
    text-align: left !important;
    width: 100% !important;
    margin: 0 auto !important;
    margin-top: 0px !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li .ps_top {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
  }
  .ps_timeline_sec .container ol.ps_timeline li .ps_bot {
    width: auto !important;
    float: none !important;
    margin: 0 !important;
  }
  .ps_timeline_sec .container {
    padding: 130px 0 !important;
  }
}